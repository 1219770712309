import { format } from 'date-fns'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DownloadIcon from '@mui/icons-material/Download'
import IconButton from '@mui/material/IconButton';
import LocationOn from '@mui/icons-material/LocationOn';
import LocationIcon from '@mui/icons-material/LocationCity'
import { Order, OrderPriority, OrderStatus, LoadingOrderAction } from 'types'
import { parseStatusName } from 'utils/order'

type ProcessorOrderBoxProps = {
  order: Order
  onOpenLocation: (latitude: string, longitude: string) => void
  onDownloadRawSearch: (order: Order, forceClose?: boolean) => void,
  loadingOrderAction: LoadingOrderAction,
}

const ProcessorOrderBox = ({
  order,
  onOpenLocation,
  onDownloadRawSearch,
  loadingOrderAction
}: ProcessorOrderBoxProps) => {
  const isDownloading = loadingOrderAction.ordNumber === order.ordNumber && loadingOrderAction.action === 'downloading'

  return (
    <Paper>
      <Box padding={2} marginBottom={2}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <LocationIcon color="info" />
            <Typography variant="h6">{order.ordAddress}</Typography>
          </Box>
          <Box> <Typography variant="caption">{format(new Date(order.saleDate), 'MM-dd-yyyy')}</Typography> </Box>
        </Box>

        <Box>
          <table cellPadding="5" cellSpacing="5">
            <tbody>
              <tr>
                <th align="left">Priority</th>
                <td>
                  <Typography color={order.ordPriority === OrderPriority.URGENT ? "red" : "primaryText"}>
                    {OrderPriority[order.ordPriority]}
                  </Typography>
                </td>
              </tr>

              <tr>
                <th align="left">Status</th>
                <td>{parseStatusName(OrderStatus[order.status] || "Unknown")}</td>
              </tr>

              <tr>
                <th align="left">Opened</th>
                <td>
                  <IconButton onClick={() => onOpenLocation(order.ordCheckOutLatitude || '0', order.ordCheckOutLongitude || '0')} color="primary">
                    <LocationOn />
                  </IconButton>
                </td>
              </tr>

              <tr>
                <th align="left">Closed</th>
                <td>
                  <IconButton onClick={() => onOpenLocation(order.ordCheckInLatitude || '0', order.ordCheckInLongitude || '0')} color="primary">
                    <LocationOn />
                  </IconButton>
                </td>
              </tr>

              <tr>
                <th align="left">County</th>
                <td>{order.countyName}</td>
              </tr>
            </tbody>
          </table>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginTop={2}
        >
          <Box>
            {order.ordCheckOutByName ? (
              <Typography color="#08bf08">Order checked in by {order.ordCheckOutByName}</Typography>
            ) : null}
          </Box>

          <Box>
            <ButtonGroup aria-label="outlined secondary button group">
              <Button disabled={isDownloading} onClick={() => onDownloadRawSearch(order)} startIcon={<DownloadIcon />}>
                {isDownloading ? 'Downloading' : 'Download'}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>

      </Box>
    </Paper>
  )
}

export default ProcessorOrderBox