import { Grid, Typography } from "@mui/material";
import CheckListRow from "../molecules/CheckListRow";
import { useEffect } from "react";
import {
  CheckListItem,
  CheckListItemTypes,
  CheckListItemValues,
  CheckListProps,
  ItemObject,
} from "../../types";
import { updateCheckerOrder } from "../../api/order";
import { useAppDispatch } from "../../hooks/redux";
import {
  allOrdersSelector,
  currentOrderSelector,
  updateNextOrder,
} from "../../store/order";
import { useSelector } from "react-redux";

export type ResponseFromServer = {
  status: number;
  message: string;
};

const CheckList = ({ actionButtonOnClick }: CheckListProps) => {
  const dispatch = useAppDispatch();
  const currentOrder = useSelector(currentOrderSelector);
  const currentOrderNumber = currentOrder?.ordNumber;
  const orders = useSelector(allOrdersSelector);

  const currentOrderSrCheckerMessage = orders.find(
    (order) => order.ordNumber === currentOrderNumber
  )?.ordCheckerReport;

  const checkAllQuestionsAnswered = (items: Array<CheckListItem>) => {
    const allQuestionsAnswered = items.filter(
      (item) =>
        item.type === CheckListItemTypes.radioGroup &&
        (item.value === CheckListItemValues.yes ||
          item.value === CheckListItemValues.done)
    ).length;
    const allQuestions = items.filter(
      (item) => item.type === CheckListItemTypes.radioGroup
    ).length;
    return allQuestionsAnswered === allQuestions;
  };

  const sendAction = async (action: CheckListItemValues, data: ItemObject) => {
    const logResponse = currentOrder
      ? await updateCheckerOrder(currentOrder?.ordNumber, action, data)
      : "error";
    const currentOrderItemsUpdated = currentOrder?.items.map((item) => {
      if (
        item.type === "radioGroup" &&
        item?.itemObj?.id === data.id &&
        item?.itemObj?.groupId === data.groupId &&
        item?.itemObj?.flowId === data.flowId
      ) {
        return {
          ...item,
          value: action,
          changeLog: logResponse,
        };
      }
      return item;
    });
    await dispatch(
      dispatch({
        type: updateNextOrder.type,
        payload: { ...currentOrder, items: currentOrderItemsUpdated },
      })
    );
    isAbleToSendAction(currentOrderItemsUpdated ?? [], action);
    return { status: 200, message: "OK" };
  };

  const isAbleToSendAction = (
    items: Array<CheckListItem>,
    action?: CheckListItemValues
  ) => {
    if (
      action &&
      (action === CheckListItemValues.yes ||
        action === CheckListItemValues.done) &&
      checkAllQuestionsAnswered(items)
    ) {
      actionButtonOnClick(true);
    } else if (action && action === CheckListItemValues.no) {
      actionButtonOnClick(false);
    } else if (checkAllQuestionsAnswered(items)) {
      actionButtonOnClick(true);
    }
  };

  const getSection = (
    checkListItem: CheckListItem,
    index: number,
    isSubsection: boolean = false
  ) => {
    switch (checkListItem.type) {
      case CheckListItemTypes.title:
        return (
          <Grid item md={12} key={index}>
            <Typography
              variant="h3"
              component="h3"
              textAlign={"left"}
              color={"#5B5B66"}
              sx={{
                fontWeight: "bold",
                fontSize: "1.125rem",
                textTransform: "uppercase",
                lineHeight: "24px",
              }}
            >
              {checkListItem.content}
            </Typography>
          </Grid>
        );
      case CheckListItemTypes.sectionTitle:
        return (
          <Grid item md={12} key={index}>
            <Typography
              variant="h3"
              component="h3"
              textAlign={"left"}
              color={"#5B5B66"}
              sx={{
                fontWeight: "bold",
                fontSize: "1.125rem",
                textTransform: "uppercase",
                lineHeight: "24px",
              }}
            >
              {checkListItem.content}
            </Typography>
          </Grid>
        );
      case CheckListItemTypes.text:
        return (
          <Grid item md={12} key={index} pl={1}>
            {checkListItem.content && (
              <Typography
                variant="body1"
                component="p"
                color="text.secondary"
                textAlign={"left"}
                dangerouslySetInnerHTML={{ __html: checkListItem.content }}
              />
            )}
          </Grid>
        );
      case CheckListItemTypes.radioGroup:
        return (
          <Grid
            item
            md={12}
            key={index}
            sx={{ paddingLeft: isSubsection ? "15px" : "0px" }}
          >
            {checkListItem?.itemObj !== undefined && (
              <CheckListRow
                radioArray={[
                  {
                    label: checkListItem?.radioLabel1 ?? "",
                    value: CheckListItemValues.yes,
                    data: checkListItem?.itemObj ?? {},
                    checked: checkListItem?.value === CheckListItemValues.yes,
                    onClick: sendAction,
                  },
                  {
                    label: checkListItem?.radioLabel2 ?? "",
                    value: CheckListItemValues.no,
                    data: checkListItem?.itemObj ?? {},
                    checked:
                      checkListItem?.value === CheckListItemValues.no ||
                      checkListItem?.value === CheckListItemValues.done,
                    onClick: sendAction,
                  },
                ]}
                questionContent={checkListItem.content ?? ""}
                actionButtonTitle={"Done"}
                data={checkListItem?.itemObj ?? {}}
                actionButtonOnClick={sendAction}
                actionText={checkListItem.doneLabel ?? ""}
                confirmText={checkListItem.doneLabel ?? ""}
                changeLog={checkListItem.changeLog ?? ""}
                value={checkListItem.value ?? CheckListItemValues.unset}
              />
            )}
          </Grid>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    isAbleToSendAction(currentOrder?.items ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);

  return (
    <Grid container spacing={1}>
      <Grid item md={12}>
        <Typography
          variant="h1"
          component="h1"
          textAlign={"left"}
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem",
            textTransform: "uppercase",
            lineHeight: "36px",
          }}
        >
          CHECKLIST
        </Typography>
      </Grid>
      <Grid item md={12} pl={1}>
        {currentOrderSrCheckerMessage ? (
          <Typography
            variant="body1"
            component="p"
            color="text.secondary"
            textAlign={"left"}
          >
            {currentOrderSrCheckerMessage}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            component="p"
            color="text.secondary"
            textAlign={"left"}
          >
            Please complete the checklist below. When you are finished, select
            Upload Search to be taken to the documents page on ClosingLink.
          </Typography>
        )}
      </Grid>
      {currentOrder?.items?.map((checkListItem: CheckListItem, index: number) =>
        checkListItem.type === "subSection"
          ? checkListItem.items?.map(
              (subCheckListItem: CheckListItem, subIndex: number) =>
                getSection(subCheckListItem, subIndex, true)
            )
          : getSection(checkListItem, index)
      )}
    </Grid>
  );
};

export default CheckList;
