import {Backdrop, CircularProgress, Grid, Tooltip, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import RadioGroup, {RadioProps} from "./RadioGroup";
import {useState} from "react";
import Button from "@mui/material/Button";
import {ResponseFromServer} from "../organisims/CheckList";
import {CheckListItemValues, ItemObject} from "../../types";

export type CheckListRowProps = {
    radioArray: Array<RadioProps>,
    questionContent: string,
    actionButtonTitle: string,
    actionButtonOnClick: (action: CheckListItemValues, itemObject: ItemObject) => Promise<ResponseFromServer>,
    confirmIcon?: string,
    actionText: string,
    confirmText?: string,
    data: ItemObject,
    changeLog?: string,
    value: CheckListItemValues,
}

const CheckListRow = ({
    radioArray,
    questionContent,
    actionButtonTitle,
    actionButtonOnClick,
    actionText,
    confirmText,
    data,
    changeLog,
    value,
}: CheckListRowProps) => {
    const [isActionConfirmed, setIsActionConfirmed] = useState<boolean>(value === 'Done');
    const [showActionRow, setShowActionRow] = useState<boolean>(value === 'Done' || value === '2');
    const [rowValue, setRowValue] = useState<CheckListItemValues>(value);
    const [waitingForResponse, setWaitingForResponse] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const toggleActionRow = () => {
        setShowActionRow(true);
        setRowValue(CheckListItemValues.no)
    }

    const handleClickYes = () => {
        setShowActionRow(false);
        setIsActionConfirmed(false);
        setRowValue(CheckListItemValues.yes);
    }

    const handleClickDone = () => {
        setIsActionConfirmed(true);
        setRowValue(CheckListItemValues.done);
    }

    const handleAction = async (
      radioValue: CheckListItemValues,
      radioData: ItemObject,
      fnToCall: (action: CheckListItemValues, itemObject: ItemObject) => Promise<ResponseFromServer>
    ): Promise<ResponseFromServer> => {
        try {
            setWaitingForResponse(true);
            setError(null);
            const response = await fnToCall(radioValue, radioData);
            if(response.status >= 400) {
                setError(response.message);
                return response;
            }
            if (response.status === 200) {
                switch (radioValue) {
                    case CheckListItemValues.yes:
                        handleClickYes();
                        break;
                    case CheckListItemValues.no:
                        toggleActionRow();
                        break;
                    case CheckListItemValues.done:
                        handleClickDone();
                        break;
                }
            }
            return response;
        } catch (e) {
            setError('Try again');
            return {status: 500, message: 'error'};
        }
        finally {
            setWaitingForResponse(false);
        }
    }

    return(
      <div style={{position: 'relative'}}>
          <div
            style={{
                position: "relative"
            }}
          >
              <Backdrop  sx={{position: 'absolute', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}  open={waitingForResponse}/>
              <Grid container spacing={2} padding={1} style={{position: 'relative'}}>

                  <Grid item md={3} textAlign={"left"}>
                      <RadioGroup name={"nameHere"} radioArray={radioArray.map((radio) => {
                          return {
                              ...radio,
                              onClick: async () => {
                                  return handleAction(radio.value, radio.data, radio.onClick);
                              },
                              checked: (radio.value === CheckListItemValues.yes ? rowValue === CheckListItemValues.yes :
                                radio.value === CheckListItemValues.done || radio.value === CheckListItemValues.no ?
                                  rowValue === CheckListItemValues.done || rowValue === CheckListItemValues.no :  false)
                          }
                      })}/>
                  </Grid>
                  <Grid item md={8} textAlign={"left"} marginY={1}>
                      <Typography variant="body1" component="p" dangerouslySetInnerHTML={{__html: questionContent}}/>
                  </Grid>
                  <Grid item md={1} textAlign={"center"}>
                      {waitingForResponse && <CircularProgress size={24}/>}
                      {!waitingForResponse && !error &&
                        <Tooltip title={<>
                            <Typography variant="body1" component="p"  textAlign={"left"}
                                        dangerouslySetInnerHTML={{__html: changeLog ?? ''}}/>
                        </>} placement="top">
                            <InfoIcon color="primary"/>
                        </Tooltip>
                      }
                      {
                        !waitingForResponse && error &&
                        <Tooltip title={<>
                            <Typography variant="body1" component="p"  textAlign={"left"}
                                        dangerouslySetInnerHTML={{__html: error}}/>
                        </>} placement="top">
                            <ErrorIcon color="error"/>
                        </Tooltip>

                      }
                  </Grid>
                  {showActionRow &&
                    <Grid container paddingY={2} alignContent="center" alignItems={"center"} style={{backgroundColor: "#EDEDF0"}}>
                        <Grid item md={3} textAlign={"center"}>
                            {
                                !isActionConfirmed ?
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={ async () => {
                                        return handleAction(CheckListItemValues.done, data, actionButtonOnClick);
                                    }}
                                  >
                                      {actionButtonTitle}
                                  </Button>
                                  :<CheckCircleIcon color={"primary"} />
                            }
                        </Grid>
                        <Grid item md={9} textAlign={"left"} paddingLeft={1}>
                            <Typography variant="body1" component="p">
                                {isActionConfirmed ? confirmText : actionText}
                            </Typography>
                        </Grid>
                    </Grid>
                  }
              </Grid>
          </div>

      </div>
          )
}

export default CheckListRow;
