import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store/configureStore'

export type Error = {
  id: number
  message: string
}

type State = {
  errors: Error[]
}

const initialState: State = {
  errors: []
}

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    errorAdded: (state, action: PayloadAction<Error>) => {
      state.errors.push({
        ...action.payload,
        id: (new Date()).getTime(),
      })
    },
    errorRemoved: (state, action: PayloadAction<Error>) => {
      state.errors = state.errors.filter(error => error.id !== action.payload.id)
    }
  }
})

export const { errorAdded, errorRemoved } = slice.actions
export default slice.reducer

// -- Selectors
export const errorsSelector = (state: RootState) => state.errors.errors || []