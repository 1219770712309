import request from 'utils/request'
import config from 'config'
import { LastUpdateProps } from 'types'

export const getLastUpdate = async (): Promise<LastUpdateProps> => {
  const { data } = await request({
    method: 'GET',
    url: `${config.api.url}/ods/lastupdate`
  })
  
  if (typeof data !== 'object')
    throw new Error(`Invalid last update response`)

  return data
}