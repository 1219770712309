import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

type ReportSubmittedModalProps = {
  shouldDisplayConfirmationModal: boolean;
  setShouldDisplayConfirmationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const ReportSubmittedModal = ({
  shouldDisplayConfirmationModal,
  setShouldDisplayConfirmationModal,
}: ReportSubmittedModalProps) => {
  const history = useHistory();
  const handleRedirect = () => history.push("/");

  return (
    <Modal
      open={shouldDisplayConfirmationModal}
      onClose={() => setShouldDisplayConfirmationModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30%",
          height: "20%",
          boxShadow: 11,
          overflow: "auto",
          p: 5,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            align="center"
          >
            Report Submitted
          </Typography>
        </Box>
        <Box sx={{ flex: 3 }}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Press Finish to return to the main screen.
          </Typography>
        </Box>
        <Box display="flex" flexDirection={"row-reverse"} marginTop={2}>
          <Button
            onClick={() => {
              setShouldDisplayConfirmationModal(false);
              handleRedirect();
            }}
            variant="contained"
            color="primary"
            sx={{ width: "30%" }}
          >
            FINISH
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReportSubmittedModal;
