import {FormControl, FormLabel, Radio, FormControlLabel, RadioGroup as MuiRadioGroup} from "@mui/material";
import PropTypes from "prop-types";
import {ResponseFromServer} from "../organisims/CheckList";
import {CheckListItemValues, ItemObject} from "../../types";

export type RadioProps = {
  label: string,
  value: CheckListItemValues,
  data: ItemObject,
  checked: boolean,
  onClick: (action: CheckListItemValues, itemObject: ItemObject) => Promise<ResponseFromServer>
}

export type RadioGroupProps = {

  name: string,
  radioGroupTitle?: string,
  defaultValue?: string,
  radioArray: Array<RadioProps>
}

const RadioGroup = ({name, radioGroupTitle, defaultValue, radioArray}: RadioGroupProps) => {

    return(
        <FormControl>
            {radioGroupTitle && <FormLabel id="demo-radio-buttons-group-label">{radioGroupTitle}</FormLabel>}
            <MuiRadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                defaultValue={defaultValue}
                name={name}

            >
                {radioArray.map((radio, index) => {
                    return <FormControlLabel key={index}
                                             value={radio.value}
                                             control={<Radio checked={radio?.checked} onClick={() => radio.onClick(radio.value, radio.data)}/>}
                                             label={radio.label} />
                })}
            </MuiRadioGroup>
        </FormControl>
    )
}

RadioGroup.propTypes = {
    label: PropTypes.string
}

export default RadioGroup;
