import { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

type CancelModalProps = {
  open: boolean
  onCancel: () => void
  onClose: () => void
}

const CancelModal = ({ open, onCancel, onClose }: CancelModalProps) => {
  const [error, setError] = useState<string | null>(null)
  const [reason, setReason] = useState<string>('')

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value)
  }

  const onSubmit = () => {
    if (reason.trim().length === 0) {
      setError(`Provide a reason`)
      return
    }

    onCancel()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          width: "380px",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: "flex",
          flexDirection: "column"
        }}>
        <Box>
          <Typography variant="h4" align="center" p={2}>Cancel Checkout ?</Typography>
        </Box>

        <Box p={2}>
          <TextField
            fullWidth
            error={!!error}
            label="Reason"
            variant="outlined"
            helperText="Please provide a Reason, why you are canceling this Order"
            onChange={onValueChange}
            value={reason}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Box mr={2}><Button onClick={onClose} variant="outlined">No</Button></Box>
          <Box>
            <Button
              onClick={onSubmit}
              variant="contained">
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CancelModal