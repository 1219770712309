import { Route, Redirect, RouteProps } from 'react-router'
import { useAppSelector } from 'hooks/redux'
import { isAuthedSelector } from 'store/user'

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const isAuthed = useAppSelector(isAuthedSelector)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthed
          ? (children)
          : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
      }
    />
  )
}

export const CheckAuthRoute = ({ children, ...rest }: RouteProps) => {
  const isAuthed = useAppSelector(isAuthedSelector)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthed
          ? <Redirect
            to={{
              pathname: "/dashboard",
            }}
          />
          : (children)
      }
    />
  )
}

export default PrivateRoute