import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ExitToApp from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { DrawerHeader } from "components/molecules/DrawerStyledComponents";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { signOut, sessionSelector } from "store/user";
import {
  allOrdersCountSelector,
  getOrdersCheckerCount,
  ordersCheckerCountSelector,
  ordersSrCheckerCountSelector,
  getAllOrders,
  getSrCheckerOrdersCount,
} from "store/order";
import CountyList from "./CountyList";
import { Roles } from "types";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface ISideBar {
  handleDrawerClose: () => void;
  roleSelected: Roles;
  setRoleSelected: React.Dispatch<React.SetStateAction<Roles>>;
}

const SideBar = ({
  handleDrawerClose,
  roleSelected,
  setRoleSelected,
}: ISideBar) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const countAll = useAppSelector(allOrdersCountSelector);
  const ordersCheckerAvailable = useSelector(ordersCheckerCountSelector);
  const srCheckerOrdersAvailable = useSelector(ordersSrCheckerCountSelector);

  const user = session.user;

  const onSignOut = () => {
    dispatch(signOut());
  };

  // -- Get all counties
  useEffect(() => {
    if (user && user.role === Roles.CHECKER) {
      (async () => {
        await dispatch(getOrdersCheckerCount());
      })();
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user.role === Roles.SRCHECKER) {
      (async () => {
        await dispatch(getSrCheckerOrdersCount());
        await dispatch(getAllOrders());
      })();
    }
  }, [dispatch, user]);

  return (
    <>
      {/* Menu Header */}
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />

      <List>
        <ListItem>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Box>
                <Box>
                  <Typography>
                    {user?.firstName} (<strong>{user?.role}</strong>){" "}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption">
                    {user?.organization}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </ListItem>
      </List>
      <Divider />

      {/* Profile info */}
      <List>
        <ListItem button onClick={onSignOut}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      </List>
      <Divider />

      {/* County List */}
      {user?.role === Roles.EXAMINER ? (
        <CountyList />
      ) : user?.role === Roles.CHECKER ? (
        <ListItem>
          <ListItemIcon>
            <Badge badgeContent={ordersCheckerAvailable} color="primary">
              <AssignmentIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={"Orders Available"} />
        </ListItem>
      ) : user?.role === Roles.SRCHECKER ? (
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItem
            button
            selected={roleSelected === Roles.SRCHECKER}
            onClick={() => setRoleSelected(Roles.SRCHECKER)}
          >
            <ListItemIcon>
              <Badge
                badgeContent={srCheckerOrdersAvailable.totalCount.forReview}
                color="primary"
              >
                <AssignmentIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Orders For Review"} />
          </ListItem>
          <Divider />
          <ListItem
            selected={roleSelected === Roles.CHECKER}
            button
            onClick={() => setRoleSelected(Roles.CHECKER)}
          >
            <ListItemIcon>
              <Badge
                badgeContent={srCheckerOrdersAvailable.totalCount.available}
                color="primary"
              >
                <AssignmentIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Orders Available"} />
            <Divider />
          </ListItem>
        </List>
      ) : (
        <ListItem>
          <ListItemIcon>
            <Badge badgeContent={countAll} color="primary">
              <AssignmentIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={"Orders Available"} />
        </ListItem>
      )}
    </>
  );
};

export default SideBar;
