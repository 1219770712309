import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import config from 'config'

type ModalMapProps = {
  open: boolean
  latitude: string
  longitude: string
  onClose: () => void
}

//
const MapModal = ({
  open,
  latitude,
  longitude,
  onClose,
}: ModalMapProps) => {

  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${config.googleApi.key}&zoom=14&q=${latitude},${longitude}`;

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
    >
      <Box
        sx={{
          minWidth: "90%",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: "flex",
          flexDirection: "column"
        }}>

        <iframe
          title="Location"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          src={mapUrl}
          allowFullScreen
        />

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button onClick={onClose} variant="outlined">Close</Button>
        </Box>
      </Box>
    </Modal>
  )
};

export default MapModal;