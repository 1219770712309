import { Order } from "types";

export const sortByPriority = (orders: Order[] = []) => {
  return orders.sort((a, b) => {
    if (a.ordPriority > b.ordPriority) return 1;
    if (a.ordPriority < b.ordPriority) return -1;

    return 0;
  });
}

export const sortBySaleDate = (orders: Order[] = []) => {
  return orders.sort((a, b) => {
    return (new Date(a.saleDate).getTime()) - (new Date(b.saleDate).getTime())
  })
}

export const parseStatusName = (status: string) => {
  return status.replaceAll('_', ' ')
}