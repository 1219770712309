import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Main,
  DrawerHeader,
} from "components/molecules/DrawerStyledComponents";
import SideBar from "screens/dashboard/SideBar";
import OrderList from "screens/dashboard/OrderList";
import { useAppSelector } from "hooks/redux";
import { currentCountySelector } from "store/counties";
import { sessionSelector } from "../../store/user";
import { Roles } from "../../types";
import TimeOutModalComponent from "components/molecules/TimeOutModal";
import { useIdleTimer } from "react-idle-timer";

const drawerWidth = 240;

export default function DashboardScreen() {
  const [open, setOpen] = useState<boolean>(true);
  const currentCounty = useAppSelector(currentCountySelector);
  const session = useAppSelector(sessionSelector);
  const [roleSelected, setRoleSelected] = useState<Roles>(Roles.SRCHECKER);
  const [TimeOutModal, setOpenTimeOutModal] = useState(false);
  const [, setRemaining] = useState<number>(0);

  if (localStorage.loggedOut) localStorage.removeItem("loggedOut");

  const user = session.user;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onIdle = () => {
    setOpenTimeOutModal(true);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1800_000, // 30 minutes
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {currentCounty && (
            <Typography variant="h6" noWrap component="div">
              {currentCounty.countyName}
            </Typography>
          )}
          {user?.role === Roles.CHECKER && (
            <Typography variant="h6" noWrap component="div">
              Orders
            </Typography>
          )}
          {user?.role === Roles.SRCHECKER &&
            roleSelected === Roles.SRCHECKER && (
              <Typography variant="h6" noWrap component="div">
                Orders For Review
              </Typography>
            )}
          {user?.role === Roles.SRCHECKER && roleSelected === Roles.CHECKER && (
            <Typography variant="h6" noWrap component="div">
              Orders
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar
          roleSelected={roleSelected}
          setRoleSelected={setRoleSelected}
          handleDrawerClose={handleDrawerClose}
        />
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        <OrderList roleSelected={roleSelected} />
      </Main>
      <TimeOutModalComponent
        open={TimeOutModal}
        handleClose={() => setOpenTimeOutModal(false)}
      />
    </Box>
  );
}
