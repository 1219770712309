import { currentCountySelector, getAllCounties } from 'store/counties'
import { AppDispatch, RootState } from "store/configureStore"
import { sessionSelector } from 'store/user'
import { Roles, LastUpdateProps } from "types";
import {
  getAllOrders,
  getOrdersByCounty
} from "store/order"
import * as logger from 'utils/logger'

// -- Refresh state
export const refreshAppState = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const currentCounty = currentCountySelector(state)
  const currentUser = sessionSelector(state)
  const userRole = currentUser.user?.role
  const currentCountyNumber = currentCounty?.countyNumber

  if (userRole === Roles.EXAMINER) {
    // refresh county List
    dispatch(getAllCounties())
    // refresh orders of selected county
    if (currentCountyNumber) {
      dispatch(getOrdersByCounty(currentCountyNumber))
    }
  } else if (userRole === Roles.PROCESSOR) {
    // refresh orders
    dispatch(getAllOrders())
  }
}

// -- Check if Refresh state is needed
type CheckUpdateTimestampsProps = {
  lastTimestampValues: LastUpdateProps,
  currentTimestampValues: LastUpdateProps | null
}
export const checkUpdateTimestampsAndRefresh = ({
  lastTimestampValues,
  currentTimestampValues
}: CheckUpdateTimestampsProps) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const currentCounty = currentCountySelector(state)
  const currentUser = sessionSelector(state)
  const userRole = currentUser.user?.role
  const currentCountyNumber = currentCounty?.countyNumber

  const isDiffTimestampsBySelectedCounty = () => {
    const foundTimestampRecord = lastTimestampValues.counties.find(record => record.id === currentCounty.countyNumber)
    if (!foundTimestampRecord) return false

    const lastTimeValue = foundTimestampRecord.lastUpdate 
    const currentTimeValue = currentTimestampValues?.counties?.find(record => record.id === currentCounty.countyNumber)?.lastUpdate 

    return lastTimeValue !== currentTimeValue
  }

  if (userRole === Roles.EXAMINER) {
    logger.info(`[Examiner] pulling County List`)
    // refresh county List
    dispatch(getAllCounties())
    // refresh orders of selected county
    if (currentCountyNumber && isDiffTimestampsBySelectedCounty()) {
      logger.log(`[Examiner] pulling county orders from county: ${currentCountyNumber}, LastTime: ${lastTimestampValues.counties[currentCounty.countyNumber].lastUpdate}, CurrentTime: ${currentTimestampValues?.counties?.[currentCounty.countyNumber].lastUpdate}`)
      dispatch(getOrdersByCounty(currentCountyNumber))
    } else {
      logger.info('[Examiner] Application does not require changes from API.')
    }
  }

  if (userRole === Roles.PROCESSOR && lastTimestampValues.processor !== currentTimestampValues?.processor) {
    logger.log(`[Processor] pulling processor data, LastTime: ${lastTimestampValues.processor}, CurrentTime: ${currentTimestampValues?.processor}`)
    // refresh orders
    dispatch(getAllOrders())
  } else if (userRole === Roles.PROCESSOR) {
    logger.info('[Processor] Application does not require changes from API.')
  }
}