// @ts-nocheck
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Paper, TextareaAutosize } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { useState } from "react";
import request from "utils/request";
import config from "config";
import ReportSubmittedModal from "./ReportSubmittedModal";

type ReportIssueModalProps = {
  openReportModal: boolean;
  handleClose: () => void;
  orderNumber: number;
};

const ReportIssueModal = ({
  openReportModal,
  handleClose,
  orderNumber,
}: ReportIssueModalProps) => {
  const [issueContent, setIssueContent] = useState("");
  const [isAbleToSendAction, setIsAbleToSendAction] = useState<boolean>(false);
  const [shouldDisplayConfirmationModal, setShouldDisplayConfirmationModal] =
    useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIssueContent(e?.currentTarget?.value);
    if (issueContent.length >= 9) {
      setIsAbleToSendAction(true);
    }
    if (issueContent.length <= 9) {
      setIsAbleToSendAction(false);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const { status } = await request({
        method: "PUT",
        data: {
          issueContent,
        },
        url: `${config.api.url}/orders/${orderNumber}/report`,
      });
      if (status === 202) {
        handleClose();
        setShouldDisplayConfirmationModal(true);
      }
    } catch (error: any) {
      console.log(error?.response);

      return error.response;
    }
  };

  return (
    <>
      <ReportSubmittedModal
        shouldDisplayConfirmationModal={shouldDisplayConfirmationModal}
        setShouldDisplayConfirmationModal={setShouldDisplayConfirmationModal}
      />
      <Modal
        open={openReportModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={onSubmit}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "30%",
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "30%",
              height: "55%",
              boxShadow: 11,
              paddingRight: "10px",
              p: 3,
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ flex: 1 }}>
                {" "}
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  component="h2"
                  align="center"
                >
                  Report Issue
                </Typography>
              </Box>
              <Box>
                {" "}
                <CloseIcon fontSize="large" onClick={() => handleClose()} />
              </Box>
            </Box>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Please list your issue with the search below for the Senior
              Reviewers.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, paddingBottom: "15px", fontWeight: "bold" }}
              >
                DESCRIBE ISSUE
                <span style={{ color: "red" }}>**</span>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, paddingBottom: "15px", fontWeight: "bold" }}
              >
                &#40;MINIMUM 10 CHARACTERS&#41;
              </Typography>
            </Box>
            <TextareaAutosize
              aria-label="minimum height"
              autoFocus={true}
              name="reportedIssueContent"
              minRows={3}
              maxRows={5}
              placeholder="Describe Issue"
              onChange={handleChange}
              style={{
                borderColor: "lightgray",
                resize: "none",
                borderStyle: "solid",
                borderBlockWidth: "1px",
                minHeight: "25%",
                paddingLeft: 10,
                paddingTop: 10,
              }}
            />
            <Box display="flex" flexDirection={"row-reverse"} marginTop={2}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ width: "30%" }}
                disabled={!isAbleToSendAction}
              >
                SUBMIT
              </Button>
            </Box>
          </Paper>
        </form>
      </Modal>
    </>
  );
};

export default ReportIssueModal;
