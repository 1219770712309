import { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import DownloadIcon from "@mui/icons-material/Download";
import Viewer from "./viewer";
import { pdfexamainer, readRunsheet } from "api/order";
import { Order, OrderExists, Roles, User } from "types";
import * as logger from "utils/logger";
import CheckList from "../../organisims/CheckList";
import ReportIssueModal from "../ReportIssueModal";
import UploadSearchModal from "../UploadSearchModal";

type PDFViewerProps = {
  open: boolean;
  onCancel: (order: Order | OrderExists) => void;
  order: Order | OrderExists;
  onDownload: (order: Order) => void;
  onDone: () => void;
  user: User | null;
  roleSelected: Roles;
};

const PDFViewer = ({
  open,
  onCancel,
  order,
  onDownload,
  onDone,
  user,
  roleSelected,
}: PDFViewerProps) => {
  const [page, setPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [buffer, setBuffer] = useState(null);
  const [isAbleToSendAction, setIsAbleToSendAction] = useState<boolean>(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [uploadSearchModal, setOpenUploadSearchModal] = useState(false);

  const ordNumber = order?.ordNumber;

  useEffect(() => {
    (async () => {
      try {
        if (user?.role === Roles.CHECKER || user?.role === Roles.SRCHECKER) {
          const pdfFromOrderResponseURL = order?.rawSearchFileURL
            ? order.rawSearchFileURL
            : "";
          const response = await pdfexamainer(pdfFromOrderResponseURL);
          setBuffer(response);
          return;
        }
        const response = await readRunsheet(ordNumber);
        setBuffer(response);
      } catch (error) {
        logger.error("Error loading PDF document => ", error);
      }
    })();
  }, [ordNumber, user, order]);

  const onDocumentComplete = useCallback((numpages: number) => {
    setNumPages(numpages);
  }, []);

  const getControls = () => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "0 1rem" }}
      >
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <Button
              disabled={page <= 1}
              onClick={() => setPage(page - 1)}
              variant="contained"
              color="secondary"
            >
              Prev
            </Button>
          </Box>

          <Box mr={1}>
            <Button
              disabled={page === numPages}
              onClick={() => setPage(page + 1)}
              variant="contained"
              color="secondary"
            >
              Next
            </Button>
          </Box>

          <Box>
            <Typography>
              Pages: {page} of {numPages}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          onClick={() => onDownload(order as Order)}
        >
          <IconButton>
            <DownloadIcon />
          </IconButton>
          <Typography
            sx={{
              cursor: "pointer",
              "&:hover": {
                opacity: "0.5",
              },
            }}
            fontWeight={500}
          >
            DOWNLOAD DOCUMENT
          </Typography>
        </Box>

        {user?.role !== Roles.CHECKER && user?.role !== Roles.SRCHECKER && (
          <Box display="flex">
            <Box mr={1}>
              <Button onClick={() => onCancel(order as Order)}>Cancel</Button>
            </Box>
            <Box>
              <Button
                onClick={() => onDone()}
                variant="contained"
                color="primary"
              >
                Done
              </Button>
            </Box>
          </Box>
        )}

        {roleSelected === Roles.CHECKER && (
          <Box display="flex">
            <Box marginLeft={"15px"}>
              <Button
                onClick={() => setOpenUploadSearchModal(true)}
                disabled={!isAbleToSendAction}
                variant="contained"
                color="primary"
              >
                UPLOAD SEARCH
              </Button>
            </Box>
          </Box>
        )}
        {user?.role === Roles.CHECKER && (
          <Box display="flex">
            <Box>
              <Button
                onClick={() => setOpenReportModal(true)}
                disabled={false}
                variant="contained"
                color="secondary"
              >
                REPORT ISSUE
              </Button>
            </Box>
            <Box marginLeft={"15px"}>
              <Button
                onClick={() => setOpenUploadSearchModal(true)}
                disabled={!isAbleToSendAction}
                variant="contained"
                color="primary"
              >
                UPLOAD SEARCH
              </Button>
            </Box>
          </Box>
        )}
        {user?.role === Roles.SRCHECKER && roleSelected === Roles.SRCHECKER && (
          <Box display="flex">
            <Box marginLeft={"15px"}>
              <Button
                onClick={() => setOpenUploadSearchModal(true)}
                disabled={!isAbleToSendAction}
                variant="contained"
                color="primary"
              >
                UPLOAD SEARCH
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  const twoColumnWrapper = (children: any) => {
    return (
      <>
        <Box
          width={"54%"}
          border="2px solid #ebebeb"
          bgcolor={"#F0F0F4"}
          overflow="auto"
          textAlign="center"
        >
          {children[0]}
        </Box>
        <Box
          width={"40%"}
          border="2px solid #ebebeb"
          p={3}
          overflow="auto"
          textAlign="center"
        >
          {children[1]}
        </Box>
        <ReportIssueModal
          openReportModal={openReportModal}
          handleClose={() => setOpenReportModal(false)}
          orderNumber={order.ordNumber}
        />
        <UploadSearchModal
          isUploadSearchModalOpen={uploadSearchModal}
          handleClose={() => setOpenUploadSearchModal(false)}
          orderNumber={order.ordNumber}
        />
      </>
    );
  };

  const displayViewer = () => {
    return buffer ? (
      <Viewer
        page={page}
        onDocumentComplete={onDocumentComplete}
        scale={1.7}
        file={buffer}
      />
    ) : (
      <LinearProgress />
    );
  };

  return (
    <Modal open={open} onClose={() => onCancel(order)}>
      <Box
        sx={{
          width: "90%",
          height: "90%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant="h4" align="center" p={2}>
            Order{" "}
            {order.hasOwnProperty("ordAddress")
              ? (order as Order)?.ordAddress
              : (order as OrderExists)?.ordAddress}
          </Typography>
        </Box>

        <Box
          flex={1}
          mr={2}
          ml={2}
          height="100%"
          overflow="auto"
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {user?.role === Roles.CHECKER || user?.role === Roles.SRCHECKER
            ? twoColumnWrapper([
                displayViewer(),
                <CheckList actionButtonOnClick={setIsAbleToSendAction} />,
              ])
            : displayViewer()}
        </Box>
        <Box p={2}>{getControls()}</Box>
      </Box>
    </Modal>
  );
};

export default PDFViewer;
