import { useState, useEffect, useMemo, useCallback } from "react";
import ExaminerOrderBox from "components/atoms/ExaminerOrderBox";
import SrCheckerOrderBox from "components/atoms/SrCheckerOrderBox";
import ProcessorOrderBox from "components/atoms/ProcessorOrderBox";
import PDFViewer from "components/molecules/pdf-viewer";
import CheckInModal from "components/molecules/CheckInModal";
import MapModal from "components/molecules/MapModal";
import CancelModal from "components/molecules/CancelModal";
import LinearProgress from "@mui/material/LinearProgress";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { currentCountySelector } from "store/counties";
import {
  getAllOrders,
  getOrdersByCounty,
  ordersByCountySelector,
  allOrdersSelector,
  processorCloseOrder,
  cancelOrder,
  openOrder,
  getNextOrder,
  currentOrderSelector,
  updateNextOrder,
} from "store/order";
import { getAllCounties } from "store/counties";
import { sessionSelector } from "store/user";
import {
  Order,
  OrderStatus,
  Roles,
  LoadingOrderAction,
  Location,
  OrderExists,
} from "types";
import * as orderApi from "api/order";
import * as logger from "utils/logger";
import CheckerOrderBox from "../../components/atoms/CheckerOrderBox";
import SrCheckerOrderBoxNoOrder from "../../components/atoms/SrCheckerOrderBoxNoOrder";
import { useSelector } from "react-redux";
import { getOrdersCheckListItems, takeSrcheckerOrder } from "api/order";

interface IOrderList {
  roleSelected: Roles;
}

const OrderList = ({ roleSelected }: IOrderList) => {
  const [orderToCancel, setOrderToCancel] = useState<
    Order | OrderExists | null
  >(null);
  const [locationToOpen, setLocationToOpen] = useState<Location | null>(null);
  const [orderToCheckIn, setOrderToCheckIn] = useState<
    Order | OrderExists | null
  >(null);
  const [orderToOpen, setOrderToOpen] = useState<
    Order | OrderExists | null | undefined
  >(null);
  const [loadingOrderAction, setLoadingOrderAction] =
    useState<LoadingOrderAction>({
      ordNumber: 0,
      action: "none",
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const currentCounty = useAppSelector(currentCountySelector);
  const currentCountyNumber = currentCounty?.countyNumber;
  const session = useAppSelector(sessionSelector);
  const user = session.user;
  const userRole = user?.role;
  const currentOrder = useSelector(currentOrderSelector);
  const orders = useAppSelector(
    userRole === Roles.EXAMINER
      ? ordersByCountySelector(currentCounty?.countyNumber)
      : allOrdersSelector
  );

  const resetLoadingOrderActions = useCallback(() => {
    setLoadingOrderAction({
      ordNumber: 0,
      action: "none",
    });
  }, []);

  // -- Filter Orders that can only seen by an Examiner / Processor
  const filteredOrders = useMemo(() => {
    if (userRole === Roles.EXAMINER)
      return orders.filter((order) =>
        [OrderStatus.RECEIVED, OrderStatus.AT_COURTHOUSE].includes(order.status)
      );
    else if (userRole === Roles.PROCESSOR) {
      return orders.filter((order) =>
        [OrderStatus.AT_PROCESSOR].includes(order.status)
      );
    } else return [];
  }, [userRole, orders]);

  // -- Load all Orders if user is a `processor`
  useEffect(() => {
    (async () => {
      if (userRole === Roles.PROCESSOR) {
        setIsLoading(true);
        await dispatch(getAllOrders());
        setIsLoading(false);
      }
    })();
  }, [dispatch, userRole]);

  // -- Load orders by county if one county is selected
  useEffect(() => {
    if (!currentCountyNumber) return;
    (async () => {
      setIsLoading(true);
      await dispatch(getOrdersByCounty(currentCountyNumber));
      setIsLoading(false);
    })();
  }, [dispatch, currentCountyNumber]);

  const OrderBox =
    user?.role === Roles.EXAMINER ? ExaminerOrderBox : ProcessorOrderBox;

  // -- Open Location
  const onOpenLocation = useCallback((latitude: string, longitude: string) => {
    setLocationToOpen({
      latitude,
      longitude,
    });
  }, []);

  // -- Download Runsheet
  const onDownloadRunsheet = useCallback(
    async (order: Order) => {
      try {
        setLoadingOrderAction({
          ordNumber: order.ordNumber,
          action: "downloading",
        });
        if (user?.role === Roles.CHECKER || user?.role === Roles.SRCHECKER) {
          const pdfFromOrderResponseURL = order?.rawSearchFileURL
            ? order.rawSearchFileURL
            : "";

          await orderApi.pdfexamainerDownload(
            pdfFromOrderResponseURL,
            order.ordNumber
          );
          return;
        }
        await orderApi.downloadRunsheet(order.ordNumber);
      } catch (error) {
        logger.error("Error downloading Runsheet document => ", error);
      } finally {
        resetLoadingOrderActions();
      }
    },
    [resetLoadingOrderActions, user]
  );

  // -- Download RawSearch
  const onDownloadRawSearch = useCallback(
    async (order: Order) => {
      try {
        setLoadingOrderAction({
          ordNumber: order.ordNumber,
          action: "downloading",
        });
        // Download RawSearch
        await orderApi.downloadRawsearch(order.ordNumber);

        // Close order
        await dispatch(processorCloseOrder(order.ordNumber));

        // Refresh current Order List
        dispatch(getAllOrders());
      } catch (error) {
        logger.error("Error downloading Runsheet document => ", error);
      } finally {
        resetLoadingOrderActions();
      }
    },
    [dispatch, resetLoadingOrderActions]
  );

  // -- Open Order
  const onOpenCurrentOrder = useCallback(
    async (order: OrderExists) => {
      setLoadingOrderAction({
        ordNumber: order.ordNumber,
        action: "opening",
      });

      const currentOrderUpdated = await dispatch(getNextOrder());
      setOrderToOpen(currentOrderUpdated);

      resetLoadingOrderActions();
    },
    [dispatch, resetLoadingOrderActions]
  );

  // -- Open  SR Checker Order
  const onOpenSrcheckerOrder = useCallback(
    async (order: Order) => {
      setLoadingOrderAction({
        ordNumber: order.ordNumber,
        action: "opening",
      });
      const { srCheckerId } = order;
      if (srCheckerId === null) {
        const openedOrder = await takeSrcheckerOrder(order.ordNumber);
        const { ordNumber } = openedOrder;
        const checkListItems = await getOrdersCheckListItems(ordNumber);
        dispatch({
          type: updateNextOrder.type,
          payload: checkListItems.data,
        });
        setOrderToOpen(checkListItems.data);
        resetLoadingOrderActions();
      }
      if (srCheckerId !== null) {
        const checkListItems = await getOrdersCheckListItems(order.ordNumber);
        dispatch({
          type: updateNextOrder.type,
          payload: checkListItems.data,
        });
        setOrderToOpen(checkListItems.data);
        resetLoadingOrderActions();
      }
    },
    [dispatch, resetLoadingOrderActions]
  );

  // -- Open Order
  const onOpenOrder = useCallback(
    async (order: Order) => {
      setLoadingOrderAction({
        ordNumber: order.ordNumber,
        action: "opening",
      });
      const openedOrder = await dispatch(openOrder(order.ordNumber));
      if (openedOrder) {
        setOrderToOpen(order);
      }

      resetLoadingOrderActions();
    },
    [dispatch, resetLoadingOrderActions]
  );

  // -- Close/Checkin Order
  const onCloseOrder = useCallback((order: Order | OrderExists) => {
    setOrderToCheckIn(order);
  }, []);

  const onDone = () => {
    if (user?.role !== Roles.CHECKER) setOrderToOpen(null);
  };

  // -- Cancel Order
  const onCancelOrder = useCallback(
    async (order: Order) => {
      setLoadingOrderAction({
        ordNumber: order.ordNumber,
        action: "cancelling",
      });
      await dispatch(cancelOrder(order.ordNumber));
      resetLoadingOrderActions();
    },
    [dispatch, resetLoadingOrderActions]
  );

  // -- On success checkin
  const onSuccessCheckIn = useCallback(() => {
    dispatch(getAllCounties());
    setOrderToCheckIn(null);
  }, [dispatch]);

  return (
    <>
      {isLoading && <LinearProgress />}

      {user?.role !== Roles.CHECKER &&
        filteredOrders.map((order) => (
          <OrderBox
            key={order.ordNumber}
            loadingOrderAction={loadingOrderAction}
            user={user}
            onCancel={(order) => setOrderToCancel(order)}
            onClose={onCloseOrder}
            onOpenLocation={onOpenLocation}
            onDownloadRunsheet={onDownloadRunsheet}
            onDownloadRawSearch={onDownloadRawSearch}
            onOpen={onOpenOrder}
            order={order}
          />
        ))}

      {roleSelected === Roles.CHECKER && (
        <CheckerOrderBox
          key={currentOrder?.ordNumber}
          loadingOrderAction={loadingOrderAction}
          user={user}
          onCancel={(order) => setOrderToCancel(order as OrderExists)}
          onClose={onCloseOrder}
          onOpen={onOpenCurrentOrder}
          order={currentOrder as OrderExists}
        />
      )}

      {user?.role === Roles.SRCHECKER &&
        roleSelected === Roles.SRCHECKER &&
        orders.map((order) => (
          <SrCheckerOrderBox
            key={order.ordNumber}
            loadingOrderAction={loadingOrderAction}
            user={user}
            onCancel={(order) => setOrderToCancel(order)}
            onClose={onCloseOrder}
            onOpen={onOpenSrcheckerOrder}
            order={order}
          />
        ))}

      {user?.role === Roles.SRCHECKER &&
        roleSelected === Roles.SRCHECKER &&
        (orders.length < 0 ? <SrCheckerOrderBoxNoOrder /> : null)}

      {user?.role === Roles.CHECKER && (
        <CheckerOrderBox
          key={currentOrder?.ordNumber}
          loadingOrderAction={loadingOrderAction}
          user={user}
          onCancel={(order) => setOrderToCancel(order as OrderExists)}
          onClose={onCloseOrder}
          onOpen={onOpenCurrentOrder}
          order={currentOrder as OrderExists}
        />
      )}

      {orderToOpen && (
        <PDFViewer
          open
          order={orderToOpen}
          onCancel={(order) => {
            if (order.hasOwnProperty("status")) {
              onCancelOrder(order as Order);
            }
            setOrderToOpen(null);
          }}
          onDone={onDone}
          onDownload={onDownloadRunsheet}
          user={user}
          roleSelected={roleSelected}
        />
      )}

      {orderToCheckIn && (
        <CheckInModal
          open
          order={orderToCheckIn as Order}
          onCancel={() => setOrderToCheckIn(null)}
          onSuccess={onSuccessCheckIn}
        />
      )}

      {locationToOpen && (
        <MapModal
          open
          onClose={() => setLocationToOpen(null)}
          {...locationToOpen}
        />
      )}

      {orderToCancel && (
        <CancelModal
          open
          onClose={() => setOrderToCancel(null)}
          onCancel={() => {
            onCancelOrder(orderToCancel as Order);
            setOrderToCancel(null);
          }}
        />
      )}
    </>
  );
};

export default OrderList;
