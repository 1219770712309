import { BrowserRouter as Router, Switch } from 'react-router-dom'
import SignInScreen from './screens/signin/SignInScreen'
import DashboardScreen from './screens/dashboard/DashboardScreen'
import SnackbarErrors from 'components/molecules/SnackbarErrors'
import PrivateRoute, { CheckAuthRoute } from './components/molecules/PrivateRoute'
import useRTMessages from 'hooks/useRTMessages'
import useCheckRemoteChanges from 'hooks/useCheckRemoteChanges'

const Routes = () => {
  useRTMessages()
  useCheckRemoteChanges()

  return (
    <>
      <Router>
        <Switch>
          <CheckAuthRoute path="/" exact>
            <SignInScreen />
          </CheckAuthRoute>

          <PrivateRoute path="/dashboard" exact>
            <DashboardScreen />
          </PrivateRoute>
        </Switch>
      </Router>

      <SnackbarErrors />
    </>
  )
}

export default Routes