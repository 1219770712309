import { useEffect, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import AssignmentIcon from '@mui/icons-material/Assignment'
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getAllCounties, countiesSelector, currentCountySelector, currentCountySet } from 'store/counties'
import { County } from 'types';

const CountyList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const counties = useAppSelector(countiesSelector)
  const currentCounty = useAppSelector(currentCountySelector)
  const currentCountyNumber = currentCounty?.countyNumber

  // -- Get all counties
  useEffect(() => {
    (async () => {
      setIsLoading(true)
      await dispatch(getAllCounties())
      setIsLoading(false)
    })()
  }, [dispatch])

  // - Set default selected county
  useEffect(() => {
    if (currentCountyNumber) return

    if (counties.length > 0) {
      dispatch({
        type: currentCountySet.type,
        payload: counties[0].countyNumber,
      })
    }
  }, [currentCountyNumber, counties, dispatch])

  const onSelectCounty = (countyNumber: number) => {
    dispatch({
      type: currentCountySet.type,
      payload: countyNumber
    })
  }

  return (
    <List>
      {isLoading && (
        <LinearProgress />
      )}

      {counties.map((county: County) => (
        <ListItemButton
          key={county.countyNumber}
          selected={currentCounty?.countyNumber === county.countyNumber}
          onClick={() => onSelectCounty(county.countyNumber)}
        >
          <ListItemIcon>
            <Badge badgeContent={county.count} color="primary">
              <AssignmentIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={county.countyName} />
        </ListItemButton>
      ))}
    </List>
  )
}

export default CountyList