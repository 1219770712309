import { AppDispatch } from "store/configureStore";
import { errorAdded } from "store/errors";
import * as logger from 'utils/logger'

export const crashReporter = (error: any, dispatch: AppDispatch) => {
  if (error?.response?.status === 401) {
    dispatch({
      type: 'reset'
    })

    dispatch({
      type: errorAdded.type,
      payload: {
        message: 'Your session has expired!',
      }
    })

    return;
  }

  const message = error.response?.data?.message || error.message || 'Unknown Error'
  logger.log(`[action crash reporter]: ${message}`, error)

  dispatch({
    type: errorAdded.type,
    payload: {
      message,
    }
  })
}