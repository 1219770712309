import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "hooks/redux";
import { signOut } from "store/user";

type TimeOutModalProps = {
  open: boolean;
  handleClose: () => void;
};

const TimeOutModal = ({ open, handleClose }: TimeOutModalProps) => {
  const [seconds, setSeconds] = useState(300); // 5  minutes
  const dispatch = useAppDispatch();
  const myInterval: any = useRef();

  useEffect(() => {
    if (open) {
      if (seconds === 0) {
        dispatch(signOut());
        localStorage.setItem("loggedOut", "true");
      }
      myInterval.current = window.setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
      return () => clearInterval(myInterval.current);
    }
  }, [seconds, dispatch, open]);

  return (
    <Modal open={open}>
      <Box
        sx={{
          width: "380px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant="h6" align="left" p={2}>
            Session Timeout
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" align="left" p={2}>
            The current session is about to expire in{" "}
            <Typography
              style={{ color: "red", fontWeight: "bold" }}
              variant="subtitle1"
              display="inline"
            >
              {""}
              {seconds}
            </Typography>{" "}
            seconds. Would you like to continue session ?
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Box mr={2}>
            <Button
              onClick={() => {
                handleClose();
                clearInterval(myInterval.current);
                setSeconds(300);
              }}
              variant="contained"
            >
              Stay Logged In
            </Button>
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TimeOutModal;
