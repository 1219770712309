//import { format } from 'date-fns'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { User, LoadingOrderAction, OrderExists } from "types";
import { Dispatch, SetStateAction } from "react";

type CheckerOrderBoxProps = {
  order: OrderExists;
  user: User | null;
  onOpen: (order: OrderExists) => void;
  onClose: (order: OrderExists) => void;
  onCancel: (order: OrderExists) => void;
  loadingOrderAction: LoadingOrderAction;
  setSendActionEnabler?: Dispatch<SetStateAction<boolean>>;
};

const CheckerOrderBox = ({
  order,
  onOpen,
  loadingOrderAction,
}: CheckerOrderBoxProps) => {
  const isOpening =
    loadingOrderAction?.ordNumber === order?.ordNumber &&
    loadingOrderAction.action === "opening";

  return (
    <Paper>
      <Box padding={2} marginBottom={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Check Order</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Typography variant="body2">
            Please press Begin Checking Order Below to check out a file.
          </Typography>
        </Box>
        <Box display="flex" flexDirection={"row-reverse"} marginTop={2}>
          <Button variant="contained" onClick={() => onOpen(order)}>
            {isOpening ? "Opening order ..." : "BEGIN CHECKING ORDER"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default CheckerOrderBox;
