const debuggerEnabled = process.env.REACT_APP_ENV !== 'production'

export const log = (message: string, ...options: any) => {
  if (debuggerEnabled)
    console.log('[ log ]', message, ...options)
}

export const info = (message: string, ...options: any) => {
  if (debuggerEnabled)
    console.log('[ info ]', message, ...options)
}

export const error = (message: string, ...options: any) => {
  if (debuggerEnabled)
    console.log('[ error ]', message, ...options)
}