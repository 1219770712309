//import { format } from 'date-fns'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const SrCheckerOrderBoxNoOrder = () => {
  return (
    <Paper>
      <Box padding={2} marginBottom={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight={500} variant="h5">
            NO ORDERS
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Typography variant="body2"></Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default SrCheckerOrderBoxNoOrder;
