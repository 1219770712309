import { format } from "date-fns";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LocationIcon from "@mui/icons-material/LocationCity";
import { User, Order, OrderPriority, LoadingOrderAction } from "types";

type SrCheckerOrderBoxProps = {
  order: Order;
  onOpen: (order: Order) => void;
  onClose: (order: Order) => void;
  onCancel: (order: Order) => void;
  loadingOrderAction: LoadingOrderAction;
  user: User | null;
};

const SrCheckerOrderBox = ({ order, onOpen }: SrCheckerOrderBoxProps) => {
  return (
    <Paper>
      <Box padding={2} marginBottom={2}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <LocationIcon color="info" />
            <Typography variant="h6">{order.ordAddress}</Typography>
          </Box>
          <Box>
            {" "}
            <Typography variant="caption">
              {format(new Date(order.saleDate), "MM-dd-yyyy")}
            </Typography>{" "}
          </Box>
        </Box>

        <Box>
          <table cellPadding="5" cellSpacing="5">
            <tbody>
              <tr>
                <th align="left">Priority</th>
                <td>
                  <Typography
                    color={
                      order.ordPriority === OrderPriority.URGENT
                        ? "red"
                        : "primaryText"
                    }
                  >
                    {OrderPriority[order.ordPriority]}
                  </Typography>
                </td>
              </tr>

              <tr>
                <th align="left">File #</th>
                <td>{order.ordFileNumber}</td>
              </tr>

              <tr>
                <th align="left">County</th>
                <td>{order.countyName}</td>
              </tr>
              <tr>
                <th align="left">Notes</th>
                <td>{order.ordCheckerReport}</td>
              </tr>
            </tbody>
          </table>
        </Box>

        <Box
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
          justifyContent="space-between"
          marginTop={2}
        >
          <Box>
            <Button
              disabled={false}
              onClick={() => onOpen(order)}
              variant="contained"
            >
              {order.srCheckerId ? "Resume Order" : "Review Order"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default SrCheckerOrderBox;
