import { combineReducers } from "redux";
import user from 'store/user'
import county from 'store/counties'
import order from 'store/order'
import errors from 'store/errors'

export default combineReducers({
  user,
  county,
  order,
  errors,
})