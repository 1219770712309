import config from "config";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./redux";
import createStorage from "utils/storage";
import { LAST_UPDATE_KEY } from "utils/constants";
import { sessionSelector, isAuthedSelector } from "store/user";
import * as commonApi from "api/common";
import { checkUpdateTimestampsAndRefresh } from "store/common";
import * as logger from "utils/logger";
import { LastUpdateProps } from "types";
import { isEqualObj } from "utils/helpers";
import { getOrdersCheckerCount, getSrCheckerOrdersCount } from "store/order";
import { Roles } from "types";

const lastUpdateStorage = createStorage(LAST_UPDATE_KEY);

// -- Hook
const useCheckRemoteChanges = () => {
  const isAuthed = useAppSelector(isAuthedSelector);
  const dispatch = useAppDispatch();
  const session = useAppSelector(sessionSelector);
  const currentUser = session.user;
  const userRole = currentUser?.role;

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (!isAuthed) {
      return;
    }

    if (
      (isAuthed && userRole === Roles.CHECKER) ||
      userRole === Roles.SRCHECKER
    ) {
      return;
    }

    // check for updates
    const check = async () => {
      logger.info(`Check API updates => ${new Date()}`);
      try {
        const lastTimestampValues = await commonApi.getLastUpdate();
        const currentTimestampValues: LastUpdateProps | null =
          lastUpdateStorage.get();

        // Persist last update timestamps
        if (!isEqualObj(lastTimestampValues, currentTimestampValues)) {
          logger.info("saving lastTimestampValues");
          lastUpdateStorage.save(lastTimestampValues);

          dispatch(
            checkUpdateTimestampsAndRefresh({
              lastTimestampValues,
              currentTimestampValues,
            })
          );
        } else {
          logger.info("lastTimestampValues EQUAL");
        }
      } catch (error: any) {
        logger.log("Error check updates from API => ", error);
      }
      // run every checkAPIUpdatesInterval ( 2 minutes )
      timerId = setTimeout(check, config.checkAPIUpdatesInterval);
    };
    check();

    // -- clean up
    return () => {
      logger.log(`Stopping check remote updates`);
      clearTimeout(timerId);
    };
  }, [isAuthed, dispatch, userRole]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (!isAuthed && userRole !== Roles.CHECKER) {
      return;
    }

    // check for updates
    const checkOrdesCounter = async () => {
      logger.info(`Check API updates getOrdersCheckerCount() => ${new Date()}`);
      if (isAuthed && userRole === Roles.CHECKER) {
        dispatch(getOrdersCheckerCount());
      }
      if (isAuthed && userRole === Roles.SRCHECKER) {
        dispatch(getSrCheckerOrdersCount());
      }

      // run every checkAPIUpdatesInterval ( 2 minutes )
      timerId = setTimeout(checkOrdesCounter, config.checkAPIUpdatesInterval);
    };
    checkOrdesCounter();

    // -- clean up
    return () => {
      logger.log(`Stopping check remote updates`);
      clearTimeout(timerId);
    };
  }, [isAuthed, dispatch, userRole]);
};

export default useCheckRemoteChanges;
