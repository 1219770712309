import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import {
  CheckListItemValues,
  ItemObject,
  Order,
  OrderExists,
  OrdersCount,
  OrderStatusClosingLink,
  totalCountResponse,
} from "types";
import * as orderApi from "api/order";
import * as orderUtils from "utils/order";
import { AppDispatch, RootState } from "store/configureStore";
import { crashReporter } from "utils/redux";

type OrdersState = {
  allByIds: {
    [id: number]: Order;
  };
  currentListByCounty: {
    [countyNumber: number]: {
      totalCount: number;
      ids: number[];
    };
  };
  allList: {
    totalCount: number;
    ids: number[];
  };
  currentOrder?: OrderExists;
  ordersCheckerCount: number;
  ordersSrCheckerCount: totalCountResponse;
  orderStatus: number;
};

const initialState: OrdersState = {
  allByIds: {},
  currentListByCounty: {
    0: { totalCount: 0, ids: [] },
  },
  allList: {
    totalCount: 0,
    ids: [],
  },
  currentOrder: {} as OrderExists,
  ordersCheckerCount: 0,
  ordersSrCheckerCount: { totalCount: { available: 0, forReview: 0 } },
  orderStatus: 0,
};

const slice = createSlice({
  name: "order",
  initialState,
  reducers: {
    fetchedByCounty: (
      state,
      action: PayloadAction<orderApi.OrdersResponse & { countyNumber: number }>
    ) => {
      // -- clean up current orders of {countyNumber}
      Object.keys(state.allByIds).forEach((key: string) => {
        const order = state.allByIds[+key];
        if (order && order.countyNumber === action.payload.countyNumber) {
          delete state.allByIds[+key];
        }
      });

      // -- save orders
      action.payload.data.forEach((order) => {
        state.allByIds[order.ordNumber] = order;
      });

      state.currentListByCounty[action.payload.countyNumber] = {
        totalCount: action.payload.totalCount,
        ids: action.payload.data.map((order) => order.ordNumber),
      };
    },
    fetchedAll: (state, action: PayloadAction<orderApi.OrdersResponse>) => {
      action.payload.data.forEach((order) => {
        state.allByIds[order.ordNumber] = order;
      });

      state.allList.totalCount = action.payload.totalCount;
      state.allList.ids = action.payload.data.map((order) => order.ordNumber);
    },
    orderModified: (state, action: PayloadAction<Order>) => {
      if (!state.allByIds[action.payload.ordNumber]) return;
      state.allByIds[action.payload.ordNumber] = action.payload;
    },
    updateNextOrder: (state, action: PayloadAction<OrderExists>) => {
      state.currentOrder = action.payload;
    },
    orderAvailable: (state, action: PayloadAction<OrdersCount>) => {
      state.ordersCheckerCount = action.payload?.totalCount;
    },
    srCheckerOrdersAvailable: (
      state,
      action: PayloadAction<totalCountResponse>
    ) => {
      state.ordersSrCheckerCount.totalCount = action.payload?.totalCount;
    },
    orderStatusClosingLink: (
      state,
      action: PayloadAction<OrderStatusClosingLink>
    ) => {
      state.orderStatus = action.payload.orderStatus;
    },
  },
  extraReducers: {
    reset: () => initialState,
  },
});

export const {
  fetchedByCounty,
  fetchedAll,
  orderModified,
  updateNextOrder,
  orderAvailable,
  srCheckerOrdersAvailable,
  orderStatusClosingLink,
} = slice.actions;

export default slice.reducer;

// -- Thunks
export const getOrdersByCounty =
  (countyNumber: number) => async (dispatch: AppDispatch) => {
    try {
      const response = await orderApi.getOrdersByCounty(countyNumber);

      dispatch({
        type: fetchedByCounty.type,
        payload: {
          ...response,
          countyNumber,
        },
      });
    } catch (error: any) {
      crashReporter(error, dispatch);
    }
  };

export const getOrdersCheckerCount = () => async (dispatch: AppDispatch) => {
  try {
    const response = await orderApi.getOrdersCheckerCount();
    dispatch({
      type: orderAvailable.type,
      payload: {
        totalCount: response.totalCount,
      },
    });
  } catch (error: any) {
    crashReporter(error, dispatch);
  }
};

export const getSrCheckerOrdersCount = () => async (dispatch: AppDispatch) => {
  try {
    const response = await orderApi.getOrdersCheckerCount();
    dispatch({
      type: srCheckerOrdersAvailable.type,
      payload: {
        totalCount: response.totalCount,
      },
    });
  } catch (error: any) {
    crashReporter(error, dispatch);
  }
};

export const getNextOrder = () => async (dispatch: AppDispatch) => {
  try {
    const response = await orderApi.getNextOrder();
    dispatch({
      type: updateNextOrder.type,
      payload: response,
    });
    return response;
  } catch (error) {
    crashReporter(error, dispatch);
  }
};

export const getAllOrders = () => async (dispatch: AppDispatch) => {
  try {
    const response = await orderApi.getAllOrders();

    dispatch({
      type: fetchedAll.type,
      payload: response,
    });
  } catch (error) {
    crashReporter(error, dispatch);
  }
};

export const getOrderStatusClosingLink =
  (ordNumber: number) => async (dispatch: AppDispatch) => {
    try {
      const response = await orderApi.getOrderStatusClosingLink(ordNumber);

      dispatch({
        type: orderStatusClosingLink.type,
        payload: response,
      });
    } catch (error) {
      crashReporter(error, dispatch);
    }
  };

export const cancelOrder =
  (ordNumber: number) => async (dispatch: AppDispatch) => {
    try {
      const order = await orderApi.cancelOrder(ordNumber);

      dispatch({
        type: orderModified.type,
        payload: order,
      });
    } catch (error) {
      crashReporter(error, dispatch);
    }
  };

export const openOrder =
  (ordNumber: number) => async (dispatch: AppDispatch) => {
    try {
      const order = await orderApi.openOrder(ordNumber);

      dispatch({
        type: orderModified.type,
        payload: order,
      });

      return order;
    } catch (error) {
      crashReporter(error, dispatch);
    }
  };

export const updateOrder =
  (ordNumber: number, value: CheckListItemValues, itemObj: ItemObject) =>
  async (dispatch: AppDispatch) => {
    try {
      const order = await orderApi.updateOrder(ordNumber, value, itemObj);

      dispatch({
        type: orderModified.type,
        payload: order,
      });

      return order;
    } catch (error) {
      crashReporter(error, dispatch);
    }
  };

export const processorCloseOrder =
  (ordNumber: number) => async (dispatch: AppDispatch) => {
    try {
      await orderApi.closeOrder(ordNumber);
    } catch (error) {
      crashReporter(error, dispatch);
    }
  };

export const checkerCloseOrder =
  (ordNumber: number) => async (dispatch: AppDispatch) => {
    try {
      await orderApi.closeOrder(ordNumber);
    } catch (error) {
      crashReporter(error, dispatch);
    }
  };

export const checkin =
  (ordNumber: number, options: orderApi.CheckInOrderProps) =>
  async (dispatch: AppDispatch) => {
    try {
      const order = await orderApi.checkInOrder(ordNumber, options);

      dispatch({
        type: orderModified.type,
        payload: order,
      });
    } catch (error) {
      crashReporter(error, dispatch);
    }
  };

// -- Selectors
export const currentOrderStatusClosingLink = (state: RootState) =>
  state.order.orderStatus;
export const currentOrderSelector = (state: RootState) =>
  state.order.currentOrder;
export const ordersCheckerCountSelector = (state: RootState) =>
  state.order.ordersCheckerCount;
export const ordersSrCheckerCountSelector = (state: RootState) =>
  state.order.ordersSrCheckerCount;

export const ordersByCountySelector = (countyNumber: number) =>
  createSelector(
    (state: RootState) => state.order.allByIds,
    (state: RootState) => {
      return state.order.currentListByCounty[countyNumber] || {};
    },
    (allByIds, currentCountyList) =>
      orderUtils.sortBySaleDate(
        currentCountyList.ids
          ? currentCountyList.ids.map((countyId) => allByIds[countyId])
          : []
      )
  );

export const allOrdersSelector = createSelector(
  (state: RootState) => state.order.allByIds,
  (state: RootState) => state.order.allList || {},
  (allByIds, allList) =>
    orderUtils.sortByPriority(
      allList.ids ? allList.ids.map((orderId) => allByIds[orderId]) : []
    )
);

export const allOrdersCountSelector = createSelector(
  (state: RootState) => state.order.allList || {},
  (allList) => allList.totalCount || 0
);
