import config from "config"
import { useEffect} from "react"
import { useAppDispatch, useAppSelector } from './redux'
import { sessionSelector, isAuthedSelector } from 'store/user'
import { Order, Roles,  } from "types";
import Pusher from "pusher-js"
import {
  getAllOrders,
  orderModified,
} from "store/order"
import { refreshAppState } from "store/common" 
import * as logger from 'utils/logger'

// -- Hook
const useRTMessages = () => {
  const session = useAppSelector(sessionSelector)
  const dispatch = useAppDispatch()
  const isAuthed = useAppSelector(isAuthedSelector)
  const currentUser = session.user
  const userRole = currentUser?.role

  useEffect(() => {
    if (!isAuthed) return

    logger.log(`Setup RT updates`)

    const pusher = new Pusher(config.rt.pusher_key || '', {
      cluster: config.rt.pusher_cluster
    });

    //Subscription for open an order
    const channel = pusher.subscribe(config.rt.channel_name || '');

    const onOpenOrder = (order: Order) => {
      logger.info(`open an Order => ${order.ordNumber}`);
      if (userRole === Roles.EXAMINER) {
        // Update the modified order 
        dispatch({
          type: orderModified.type,
          payload: order 
        })
      }  
    }
    channel.bind(config.rt.open_order_event, onOpenOrder);

    // Subscription for cancel an Order
    const onCancelOrder = (order: Order) => {
      logger.info(`cancel an Order => ${order.ordNumber}`);
      if (userRole === Roles.EXAMINER) {
         dispatch({
          type: orderModified.type,
          payload: order
        })
      }
    }
    channel.bind(config.rt.cancel_order_event, onCancelOrder);

    // Subscription for checkIn an order for examiner
    const onCheckInOrder = (order: Order) => {
      logger.info(`checkIn the order => ${order?.ordNumber || 'Order is not provided'}`);
      dispatch(refreshAppState()) 
    }
    channel.bind(config.rt.checkIn_order_event, onCheckInOrder);

    // Subscription for order final review an order for processor
    const onReviewOrder = (order: Order) => {
      logger.info(`final review the order => ${order?.ordNumber || 'Order is not provided'}`);
      if (userRole === Roles.PROCESSOR) {
        // refresh orders
        dispatch(getAllOrders())
      }
    }
    channel.bind(config.rt.review_order_event, onReviewOrder);

    // Clean up
    return () => {
      logger.log('Unsubscribing from RT updates')

      pusher.unsubscribe(config.rt.channel_name || '')
      channel.unbind(config.rt.open_order_event, onOpenOrder)
      channel.unbind(config.rt.cancel_order_event, onCancelOrder)
      channel.unbind(config.rt.checkIn_order_event, onCheckInOrder)
      channel.unbind(config.rt.review_order_event, onReviewOrder)
    }
  }, [isAuthed, dispatch, userRole ])
}

export default useRTMessages