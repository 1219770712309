import React, { useState } from 'react'
import { useAppDispatch } from "hooks/redux"
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Order } from 'types'
import { checkin } from 'store/order'

type CheckInModalProps = {
  open: boolean
  onCancel: () => void
  order: Order
  onSuccess: () => void
}

type FormValues = {
  notes: string
  rawSearchDocument: File | ''
  noFind: boolean
}
const formValuesInitState: FormValues = {
  notes: "",
  rawSearchDocument: '',
  noFind: false
}

type ErrorValues = {
  notes: string | null
  rawSearchDocument: string | null
  noFind: string | null
}
const errorValuesInitState: ErrorValues = {
  notes: null,
  rawSearchDocument: null,
  noFind: null
}

const CheckInModal = ({
  open,
  onCancel,
  order,
  onSuccess
}: CheckInModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errors, setErrors] = useState<ErrorValues>(errorValuesInitState)
  const [values, setValues] = useState<FormValues>(formValuesInitState)
  const dispatch = useAppDispatch()

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!values.noFind && !values.rawSearchDocument) {
      setErrors(errors => ({
        ...errors,
        rawSearchDocument: 'Document is required, please attach one',
      }))

      return
    }

    if (values.noFind && !values.notes) {
      setErrors(errors => ({
        ...errors,
        notes: 'Notes are required describing your findings',
      }))

      return
    }

    setErrors(errorValuesInitState)

    setIsSubmitting(true)
    await dispatch(checkin(order.ordNumber, values))
    setIsSubmitting(false)

    onSuccess()
  }

  const onValuesChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if ('files' in e.target && e.target?.files?.[0]) {
      const file = e.target.files[0]
      setValues(values => ({
        ...values,
        [e.target.name]: file
      }))
    } else if ('noFind' === e.target.name) {
      setValues(values => ({
        ...values,
        [e.target.name]: !values.noFind
      }))
    } else {
      setValues(values => ({
        ...values,
        [e.target.name]: e.target.value
      }))
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => onCancel()}
    >
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          minWidth: "40%",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: "flex",
          flexDirection: "column"
        }}>
        <Box>
          <Typography variant="h4" align="center" p={2}>{order.ordAddress}</Typography>
        </Box>

        <Box
          flex={1}
          mr={2}
          ml={2}
          p={2}
          height="100%"
          overflow="auto"
        >
          <TextField
            error={!!errors.notes}
            name="notes"
            fullWidth
            label="Describe your findings"
            multiline
            margin="dense"
            value={values.notes}
            onChange={onValuesChange}
          />

          {!values.noFind ? (
            <TextField
              name="rawSearchDocument"
              error={!!errors.rawSearchDocument}
              fullWidth
              type="file"
              margin="dense"
              onChange={onValuesChange}
            />
          ) : null}

          <FormControlLabel
            control={
              <Checkbox
                name="noFind"
                onChange={onValuesChange}
                checked={values.noFind}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="No Find"
          />
        </Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Box mr={2}><Button onClick={onCancel} variant="outlined">Cancel</Button></Box>
          <Box>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained">
                {isSubmitting ? 'Submitting ...' : 'Check In'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CheckInModal