import { Snackbar, Alert } from "@mui/material";
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { errorsSelector, errorRemoved, Error } from 'store/errors';

const SnackbarErrors = () => {
  const dispatch = useAppDispatch()
  const errors = useAppSelector(errorsSelector)

  const onErrorRemove = (error: Error) => {
    dispatch(errorRemoved(error))
  }

  return (
    <>
      {errors.map((error) => (
        <Snackbar
          key={`error-${error.id}`}
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={() => onErrorRemove(error)}>
          <Alert
            variant="filled"
            onClose={() => onErrorRemove(error)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  )
}

export default SnackbarErrors