import request from 'utils/request'
import config from 'config'
import { County } from 'types'

export type CountiesResponse = {
  totalCount: number,
  data: [County] 
}

export const getAllCounties = async (): Promise<CountiesResponse> => {
  const { data } = await request({
    url: `${config.api.url}/counties/all`
  })

  return data
}