import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { useSelector } from "react-redux";
import {
  currentOrderSelector,
  currentOrderStatusClosingLink,
  getOrderStatusClosingLink,
} from "store/order";
import { useAppDispatch } from "hooks/redux";
import { useEffect, useRef, useState } from "react";
import OrderCompleteModal from "./OrderCompleteModal";
import { HeaderValues } from "utils/request";
import createStorage from "utils/storage";
import { HEADER_VALUES_KEY } from "utils/constants";

type UploadSearchModalProps = {
  isUploadSearchModalOpen: boolean;
  handleClose: () => void;
  orderNumber: number;
};

const UploadSearchModal = ({
  isUploadSearchModalOpen,
  handleClose,
  orderNumber,
}: UploadSearchModalProps) => {
  const dispatch = useAppDispatch();
  const currentOrder = useSelector(currentOrderSelector);
  const currentStatusClosingLink = useSelector(currentOrderStatusClosingLink);
  const [wasToggleTriggered, setWasToggleTriggered] = useState(false);
  const [shouldDisplayOrderCompleteModal, setShouldDisplayORderCompleteModal] =
    useState(false);
  const myInterval: any = useRef();
  const headersStorage = createStorage(HEADER_VALUES_KEY);
  const headerValues = headersStorage.get<HeaderValues>();

  const handleUploadLinkButton = () => {
    if (currentOrder && headerValues) {
      let { uploadURL } = currentOrder;
      const encodedToken = encodeURIComponent(headerValues.token);
      const uploadURLWithToken = uploadURL.replace("@TOKEN@", encodedToken);
      window.open(uploadURLWithToken);
    }
  };

  useEffect(() => {
    if (isUploadSearchModalOpen && !wasToggleTriggered) {
      myInterval.current = window.setInterval(() => {
        dispatch(getOrderStatusClosingLink(orderNumber));
      }, 10000);
      setWasToggleTriggered(true);
    }
    // eslint-disable-next-line
  }, [isUploadSearchModalOpen]);

  useEffect(() => {
    if (currentStatusClosingLink === 5) {
      clearInterval(myInterval.current);
      handleClose();
      setShouldDisplayORderCompleteModal(true);
    }
  }, [currentStatusClosingLink, handleClose]);

  return (
    <>
      <OrderCompleteModal
        shouldDisplayOrderCompleteModal={shouldDisplayOrderCompleteModal}
        orderNumber={orderNumber}
        handleCloseOrderCompleteModal={() =>
          setShouldDisplayORderCompleteModal(false)
        }
      />
      <Modal
        open={isUploadSearchModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "20%",
            boxShadow: 11,
            overflow: "auto",
            p: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1 }}>
              {" "}
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                align="center"
              >
                Upload Search
              </Typography>
            </Box>
            <Box>
              {" "}
              <CloseIcon fontSize="large" onClick={() => handleClose()} />
            </Box>
          </Box>
          <Box sx={{ flex: 3 }}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Please press the button below to be taken to the documents page on
              ClosingLink. Once the order is complete and you return here from
              ClosingLink, it will take a few seconds for ODS to detect the
              change.
            </Typography>
          </Box>

          <Button
            onClick={handleUploadLinkButton}
            variant="contained"
            color="primary"
          >
            OPEN CLOSING LINK
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default UploadSearchModal;
