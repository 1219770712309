import { format } from 'date-fns'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DownloadIcon from '@mui/icons-material/Download'
import LocationIcon from '@mui/icons-material/LocationCity'
import { User, Order, OrderPriority, OrderStatus, LoadingOrderAction } from 'types'

type ExaminerOrderBoxProps = {
  order: Order,
  onDownloadRunsheet: (order: Order) => void,
  onOpen: (order: Order) => void,
  onClose: (order: Order) => void,
  onCancel: (order: Order) => void,
  loadingOrderAction: LoadingOrderAction,
  user: User | null,
}

const ExaminerOrderBox = ({
  order,
  onDownloadRunsheet,
  onOpen,
  onClose,
  onCancel,
  user,
  loadingOrderAction
}: ExaminerOrderBoxProps) => {
  const isMine = order.ordCheckOutById === user?.id
  const isDownloading = loadingOrderAction.ordNumber === order.ordNumber && loadingOrderAction.action === 'downloading'
  const isCancelling = loadingOrderAction.ordNumber === order.ordNumber && loadingOrderAction.action === 'cancelling'
  const isOpening = loadingOrderAction.ordNumber === order.ordNumber && loadingOrderAction.action === 'opening'

  return (
    <Paper>
      <Box padding={2} marginBottom={2}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <LocationIcon color="info" />
            <Typography variant="h6">{order.ordAddress}</Typography>
          </Box>
          <Box> <Typography variant="caption">{format(new Date(order.saleDate), 'MM-dd-yyyy')}</Typography> </Box>
        </Box>

        <Box>
          <table cellPadding="5" cellSpacing="5">
            <tbody>
              <tr>
                <th align="left">Priority</th>
                <td>
                  <Typography color={order.ordPriority === OrderPriority.URGENT ? "red" : "primaryText"}>
                    {OrderPriority[order.ordPriority]}
                  </Typography>
                </td>
              </tr>

              <tr>
                <th align="left">File #</th>
                <td>{order.ordFileNumber}</td>
              </tr>

              <tr>
                <th align="left">County</th>
                <td>{order.countyName}</td>
              </tr>
            </tbody>
          </table>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginTop={2}
        >
          <Box>
            {order.status === OrderStatus.AT_COURTHOUSE && (
              <Typography color="#08bf08">This search has been checked out</Typography>
            )}
          </Box>

          <Box>
            {order.status === OrderStatus.AT_COURTHOUSE && isMine && (
              <ButtonGroup aria-label="outlined secondary button group">
                <Button
                  disabled={isDownloading}
                  onClick={() => onDownloadRunsheet(order)}
                  startIcon={<DownloadIcon
                />}>
                  {isDownloading ? 'Downloading ...' : 'Download'}
                </Button>
                <Button
                  disabled={isCancelling}
                  onClick={() => onCancel(order)}>
                  {isCancelling ? 'Cancelling ...' : 'Cancel'}
                </Button>
                <Button onClick={() => onClose(order)} variant="contained" color="info">Check in</Button>
              </ButtonGroup>
            )}

            {order.status === OrderStatus.RECEIVED && (
              <Button
                disabled={isOpening}
                onClick={() => onOpen(order)}
                variant="outlined">
                {isOpening ? 'Checking out ...' : 'Check Out'}
              </Button>
            )}
          </Box>
        </Box>

      </Box>
    </Paper>
  )
}

export default ExaminerOrderBox