import Providers from 'Providers';
import Routes from 'Routes';

function App() {
  return (
    <>
      <Providers>
        <Routes />
      </Providers>
    </>
  );
}

export default App;
