import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "hooks/redux";
import { Typography } from "@mui/material";
import { signIn } from "store/user";
import appPackage from "../../../package.json";
import config from "config";
import * as logger from "utils/logger";

logger.info("config => ", config);

type FormValues = {
  username?: string;
  password?: string;
};

const SignInScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [values, setValues] = useState<FormValues>({});
  const dispatch = useAppDispatch();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!values.username || !values.password) return;

    try {
      setIsLoading(true);
      await dispatch(
        signIn({
          username: values.username,
          password: values.password,
        })
      );
    } catch (error) {
      logger.error("Error when trying to signin => ", error);
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: "url(/assets/courthhouse-1.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />

      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
          backdropFilter: "blur(12px)",
        }}
      >
        <Paper
          sx={{
            minWidth: 300,
            padding: 4,
            background: "white",
            position: "relative",
            zIndex: 2,
          }}
          elevation={3}
        >
          {localStorage.loggedOut ? (
            <Box
              sx={{
                backgroundColor: "red",
                marginBottom: 0.8,
                maxWidth: 300,
                padding: 1.5,
                borderRadius: 1,
              }}
            >
              <Typography color={"white"} variant="subtitle2" align="justify">
                Logged out due to inactivity. If unexpected, you may have ODS
                open in a different window.
              </Typography>
            </Box>
          ) : null}

          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h4" align="center">
              ODS System
            </Typography>
          </Box>

          <Box
            sx={{
              marginBottom: 1,
            }}
          >
            <TextField
              fullWidth
              name="username"
              label="Username"
              onChange={onChange}
            />
          </Box>

          <Box
            sx={{
              marginBottom: 1,
            }}
          >
            <TextField
              fullWidth
              name="password"
              type="password"
              label="Password"
              onChange={onChange}
            />
          </Box>

          <Box mt={2}>
            <Button
              size="large"
              variant="contained"
              disabled={isLoading}
              type="submit"
              fullWidth
            >
              {isLoading ? "Signing in ..." : "Sign In"}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box
        sx={{
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          textAlign: "center",
          padding: 4,
        }}
      >
        <Typography color="white">
          {" "}
          ODS System v{appPackage.version} - ({process.env.REACT_APP_ENV}){" "}
        </Typography>
      </Box>
    </>
  );
};

export default SignInScreen;
