import request from 'utils/request'
import config from 'config'

export type SignInProps = {
  username: string
  password: string
}

export const signIn = async ({ username, password }: SignInProps): Promise<any> => {
  const { data } = await request({
    method: 'POST',
    url: `${config.api.url}/users/login`, 
    auth: {
      username,
      password
    }
  })

  return data
}