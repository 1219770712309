import { Roles } from "types";

const getRole = (roles: [string]) => {
  const _roles = roles.map((role) => role.toLocaleLowerCase());

  if (_roles.includes(Roles.EXAMINER)) return Roles.EXAMINER;
  if (_roles.includes(Roles.TYPIST)) return Roles.PROCESSOR;
  if (_roles.includes(Roles.CHECKER) && !_roles.includes(Roles.SRCHECKER))
    return Roles.CHECKER;
  if (_roles.includes(Roles.SRCHECKER)) return Roles.SRCHECKER;

  return "unknown";
};

export const normalizeUser = (data: any) => {
  return {
    ...data,
    role: getRole(data.role || []),
  };
};
