import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "hooks/redux";
import {
  checkerCloseOrder,
  getNextOrder,
  getOrdersCheckerCount,
  orderStatusClosingLink,
} from "store/order";
import { useHistory } from "react-router-dom";
import { useState } from "react";

type OrderCompleteModalProps = {
  shouldDisplayOrderCompleteModal: boolean;
  handleCloseOrderCompleteModal: () => void;
  orderNumber: number;
};

const OrderCompleteModal = ({
  shouldDisplayOrderCompleteModal,
  handleCloseOrderCompleteModal,
  orderNumber,
}: OrderCompleteModalProps) => {
  const [buttonDisableToggle, setbuttonDisableToggle] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleRedirect = () => history.push("/");

  return (
    <Modal
      open={shouldDisplayOrderCompleteModal}
      onClose={() => handleCloseOrderCompleteModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30%",
          height: "20%",
          boxShadow: 11,
          overflow: "auto",
          p: 5,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            align="center"
          >
            Order Complete
          </Typography>
        </Box>
        <Box sx={{ flex: 3 }}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Press Finish to return to the main screen
          </Typography>
        </Box>
        <Box display="flex" flexDirection={"row-reverse"} marginTop={2}>
          <LoadingButton
            disabled={buttonDisableToggle}
            loading={buttonDisableToggle}
            onClick={async () => {
              setbuttonDisableToggle(true);
              await dispatch(checkerCloseOrder(orderNumber));
              await dispatch(getNextOrder());
              await dispatch(getOrdersCheckerCount());
              await dispatch({
                type: orderStatusClosingLink.type,
                payload: 0,
              });
              handleCloseOrderCompleteModal();
              handleRedirect();
            }}
            variant="contained"
            color="primary"
            sx={{ width: "30%" }}
          >
            FINISH
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderCompleteModal;
