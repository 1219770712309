export type User = {
  id: number;
  userId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  organization?: string;
  organizationId?: number;
  organizationType?: string;
  organizationTypeId?: number;
  role: "examiner" | "typist" | "processor" | "checker" | "senior_checker";
  ods_limit?: number;
  ods_limit_opened_order?: number;
};

export enum CheckListItemValues {
  unset = "unset",
  yes = "1",
  no = "2",
  done = "Done",
}

export enum CheckListItemTypes {
  title = "title",
  sectionTitle = "sectionTitle",
  text = "text",
  radioGroup = "radioGroup",
  subSection = "subSection",
}

export type County = {
  countyNumber: number;
  countyName: string;
  count: number;
};

export type OrderExists = {
  ordNumber: number;
  ordAddress: string;
  rawSearchFileURL: string;
  items: Array<CheckListItem>;
  uploadURL: string;
};

export type totalCountResponse = {
  totalCount: { available: number; forReview: number };
};

export type OrdersCount = {
  totalCount: number;
};

export type OrderStatusClosingLink = {
  orderStatus: number;
};

export type ItemObject = {
  flowId?: number;
  groupId?: number;
  id?: number;
  subId?: string;
};

export type CheckListItem = {
  type: CheckListItemTypes;
  changeLog?: string;
  content?: string;
  radioLabel1?: string;
  radioLabel2?: string;
  doneLabel?: string;
  value?: CheckListItemValues;
  itemObj?: ItemObject;
  items?: Array<CheckListItem>;
};

export type CheckListProps = {
  actionButtonOnClick: (isAbleToSendAction: boolean) => void;
};

export type Order = {
  ordNumber: number;
  isUpdate?: boolean;
  isCopy?: boolean;
  ordPriority: OrderPriority;
  ordFileNumber?: string;
  countyNumber?: number;
  countyName?: string;
  ordAddress?: string;
  ordState?: string;
  saleDate: string;
  status: OrderStatus;
  ordClosingDate?: string;
  ordGenerated?: string;
  ordSearchType?: string;
  ordSearchType_Client?: string;
  ordPurchaserName_1?: string;
  ordCheckOutBy?: string;
  ordCheckOutById?: number;
  ordCheckOutByName?: string;
  ordCheckOutLatitude?: string;
  ordCheckOutLongitude?: string;
  ordCheckOutTimeStamp?: string;
  ordCheckInBy: string;
  ordCheckInById: number;
  ordCheckInByName: string;
  ordCheckInLatitude: string;
  ordCheckInLongitude: string;
  ordCheckInTimeStamp: string;
  ordTTSNumber?: number;
  srChecker?: string;
  srCheckerId?: string;
  srCheckerName?: string;
  ordCheckerReport?: string;
  rawSearchFileURL?: string;
};

export type Session = {
  user: User | null;
};

export enum Roles {
  EXAMINER = "examiner",
  TYPIST = "typist",
  PROCESSOR = "processor",
  CHECKER = "checker",
  SRCHECKER = "senior_checker",
}

export enum OrderStatus {
  RECEIVED = 1,
  AT_COURTHOUSE = 2,
  AT_PROCESSOR = 3,
  FINAL_REVIEW = 4,
}

export enum OrderPriority {
  URGENT = 0,
  HIGH = 1,
  NORMAL = 2,
}

export type LoadingStatus =
  | "downloading"
  | "cancelling"
  | "closing"
  | "opening"
  | "none";

export type LoadingOrderAction = {
  ordNumber: number;
  action: LoadingStatus;
};

export type Location = {
  latitude: string;
  longitude: string;
};

export type LastUpdateProps = {
  processor: Date;
  counties: [
    {
      id: number;
      lastUpdate: Date;
    }
  ];
};
