export default function createStorage(key: string) {

  const save = <T>(obj: T): T => {
    window.localStorage.setItem(key, JSON.stringify(obj)) 
    return obj
  }

  const get = <T>(): T | null => {
    const obj: T = JSON.parse(window.localStorage.getItem(key) || 'null')

    return obj;
  }

  const remove = () => {
    window.localStorage.removeItem(key)
  }

  return {
    save,
    get,
    remove
  }
}