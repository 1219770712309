import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { County } from 'types'
import * as countyApi from 'api/county' 
import { AppDispatch, RootState } from "store/configureStore";
import { crashReporter } from "utils/redux";

type CountiesState = {
  currentCountyId: number
  allByIds: {
    [id: number]: County 
  }
  currentList: {
    totalCount: number
    ids: number[]
  }
}

const initialState: CountiesState = {
  currentCountyId: 0,
  allByIds: {},
  currentList: { totalCount: 0, ids: [] }
}

const slice = createSlice({
  name: 'county',
  initialState,
  reducers: {
    fetched: (state, action: PayloadAction<countyApi.CountiesResponse>) => {
      state.allByIds = {} 
      action.payload.data.forEach(county => {
        state.allByIds[county.countyNumber] = county
      })

      state.currentList.totalCount = action.payload.totalCount
      state.currentList.ids = action.payload.data.map((county) => county.countyNumber)
    },
    currentCountySet: (state, action: PayloadAction<number>) => {
      state.currentCountyId = action.payload
    }
  },
  extraReducers: {
    reset: () => initialState,
  },
})

export const { fetched, currentCountySet } = slice.actions

export default slice.reducer

// -- Thunks
export const getAllCounties = () => async (dispatch: AppDispatch) => {
  try {
    const response = await countyApi.getAllCounties()

    dispatch({
      type: fetched.type,
      payload: response 
    })
  } catch(error) {
    crashReporter(error, dispatch)
  }
}

// -- Selectors
export const countiesSelector = createSelector(
  (state: RootState) => state.county.currentList,
  (state: RootState) => state.county.allByIds,
  (currentList, allByIds) => currentList.ids.map(id => allByIds[id])
)

export const currentCountySelector = createSelector(
  (state: RootState) => state.county.currentCountyId,
  (state: RootState) => state.county.allByIds,
  (currentCountyId, allByIds) => allByIds[currentCountyId]
)