import axios, { AxiosRequestConfig } from "axios";
import differenceInMinutes from "date-fns/differenceInMinutes";
import config from "config";
import createStorage from "utils/storage";
import { HEADER_VALUES_KEY } from "utils/constants";
import * as logger from "utils/logger";

export type HeaderValues = {
  token: string;
  role: string;
  tokenExpiration: string;
};

const headersStorage = createStorage(HEADER_VALUES_KEY);

const httpRequest = async (options: AxiosRequestConfig) => {
  const savedHeaderValues = await getHeaderValues();
  const hasOrdersinRequest = options?.url
    ? options.url.includes("orders")
    : false;

  if (savedHeaderValues === null && hasOrdersinRequest) {
    localStorage.setItem("loggedOut", "true");
    window.location.reload();
  }

  const headers: HeadersInit = {
    ...options.headers,
    "Content-Type": "application/json",
    "api-key": config.api.key || "",
  };

  if (savedHeaderValues) {
    const { token, role } = savedHeaderValues;
    headers.Authorization = `Bearer ${token}`;
    headers.role = role;
  }

  return axios({
    headers,
    ...options,
  });
};

const getHeaderValues = async (): Promise<HeaderValues | null> => {
  const headerValues = headersStorage.get<HeaderValues>();

  if (
    headerValues?.token &&
    headerValues.tokenExpiration &&
    differenceInMinutes(new Date(headerValues.tokenExpiration), new Date()) <=
      15
  ) {
    try {
      logger.info("refresh token");
      const { data }: any = await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": config.api.key || "",
          Authorization: `Bearer ${headerValues.token}`,
        },
        url: `${config.api.url}/users/refresh`,
      });

      const { token, tokenExpiration } = data.data;
      headersStorage.save<HeaderValues>({
        ...headerValues,
        token,
        tokenExpiration,
      });

      return {
        ...headerValues,
        token,
        tokenExpiration,
      };
    } catch (error: any) {
      logger.error(`Error refreshing token: ${error.message}`, error);
    }
  }

  return headerValues;
};

export default httpRequest;
