export const getLocation = (): Promise<GeolocationPosition> => (
  new Promise((resolve, reject) => {
    const options = {
      enableHighAccuracy: true,
      timeout: 20000,
      maximumAge: 75000,
    };

    navigator.geolocation.getCurrentPosition(
      position => resolve(position),
      err => reject(err),
      options
    );
  })
)