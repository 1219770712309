import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Session } from "types"
import * as userApi from "api/user"
import { AppDispatch, RootState } from "store/configureStore"
import * as userUtils from 'utils/user'
import createStorage from 'utils/storage'
import { HeaderValues } from "utils/request"
import { crashReporter } from "utils/redux"
import { HEADER_VALUES_KEY } from "utils/constants"

const headersStorage = createStorage(HEADER_VALUES_KEY)

const initialState: Session = {
  user: null,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authed: (state, action: PayloadAction<Session>) => {
      state.user = action.payload.user
    }
  },
  extraReducers: {
    reset: () => initialState,
  },
})

export const { authed } = slice.actions

export default slice.reducer

// -- SignIn
export const signIn = ({ username, password }: userApi.SignInProps) => async (dispatch: AppDispatch) => {
  try {
    const {data} = await userApi.signIn({ username, password })
    const {token, tokenExpiration, ...user} = data
    const normalizedUser = userUtils.normalizeUser(user) 

    headersStorage.save<HeaderValues>({ token, role: normalizedUser.role, tokenExpiration })

    dispatch({
      type: authed.type,
      payload: {
        user: normalizedUser,
        token: token,
      }
    })
  } catch(error: any) {
    crashReporter(new Error(`Incorrect username and password`), dispatch)
    throw error
  }
}

// -- SignOut
export const signOut = () => (dispatch: AppDispatch) => {
  headersStorage.remove()

  dispatch({
    type: 'reset'
  })
}

// -- Selectors
export const isAuthedSelector = (state: RootState) => !!state.user?.user?.id
export const sessionSelector = (state: RootState) => state.user