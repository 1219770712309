const config = {
  api: {
    url: process.env.REACT_APP_API,
    key: process.env.REACT_APP_API_KEY 
  },
  googleApi: {
    key: process.env.REACT_APP_GOOGLE_API_KEY,
  },
  rt: {
    pusher_key: process.env.REACT_APP_RT_PUSHER_KEY,
    pusher_cluster:process.env.REACT_APP_RT_PUSHER_CLUSTER,
    channel_name:process.env.REACT_APP_RT_PUSHER_CHANNEL,
    open_order_event: "order-open",
    cancel_order_event: "order-cancel",
    checkIn_order_event: "order-checkIn",
    review_order_event: "final-review"
  },
  checkAPIUpdatesInterval: 5 * 60000 
}
export default config