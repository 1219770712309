import request from "utils/request";
import config from "config";
import {
  CheckListItemValues,
  ItemObject,
  Order,
  OrderExists,
  OrderStatusClosingLink,
  totalCountResponse,
} from "types";
import { getLocation } from "utils/location";

export type OrdersResponse = {
  totalCount: number;
  data: [Order];
};

export type OrdersCountResponse = {
  totalCount: number | totalCountResponse;
};

export type OrderExistsResponse = {
  orderExist: boolean;
};

export type OrderResponse = {};

export const getOrdersByCounty = async (
  countyNumber: number
): Promise<OrdersResponse> => {
  const { data } = await request({
    url: `${config.api.url}/orders/county/${countyNumber}`,
  });

  return data;
};

export const getOrdersCheckerCount = async (): Promise<OrdersCountResponse> => {
  const { data } = await request({
    url: `${config.api.url}/orders/count`,
  });

  return data;
};

export const getAllOrders = async (): Promise<OrdersResponse> => {
  const { data } = await request({
    url: `${config.api.url}/orders/all`,
  });

  return data;
};

export const getNextOrder = async (): Promise<OrderExists> => {
  const { data } = await request({
    url: `${config.api.url}/orders/request`,
  });
  return data?.orderExist ?? null;
};

export const getOrderStatusClosingLink = async (
  ordNumber: number
): Promise<OrderStatusClosingLink> => {
  const { data } = await request({
    url: `${config.api.url}/orders/${ordNumber}/status`,
  });
  return data;
};

export const updateCheckerOrder = async (
  ordNumber: number,
  value: CheckListItemValues,
  itemObj: ItemObject
): Promise<string> => {
  const { data } = await request({
    method: "PUT",
    data: {
      itemObj,
      value,
    },
    url: `${config.api.url}/orders/${ordNumber}/update`,
  });

  return data;
};

export const getOrderExists = async (): Promise<OrderExistsResponse> => {
  const { data } = await request({
    url: `${config.api.url}/orders/exists`,
  });

  return data;
};

export const closeOrder = async (ordNumber: number): Promise<Order> => {
  const { data } = await request({
    method: "PUT",
    data: {
      timeStamp: new Date().toISOString(),
    },
    url: `${config.api.url}/orders/${ordNumber}/close`,
  });

  return data;
};

export const getOrdersCheckListItems = async (orderNumber: number) => {
  const response = await request({
    url: `${config.api.url}/orders/${orderNumber}`,
  });

  return response.data;
};

export const takeSrcheckerOrder = async (ordNumber: number): Promise<Order> => {
  const { data } = await request({
    method: "PUT",
    url: `${config.api.url}/orders/${ordNumber}/take`,
  });

  return data;
};

export const updateOrder = async (
  ordNumber: number,
  value: CheckListItemValues,
  itemObj: ItemObject
): Promise<Order> => {
  const { data } = await request({
    method: "PUT",
    data: {
      itemObj,
      value,
    },
    url: `${config.api.url}/orders/${ordNumber}/update`,
  });

  return data;
};

export type CheckInOrderProps = {
  notes: string;
  rawSearchDocument: File | "";
  noFind: boolean;
};
export const checkInOrder = async (
  ordNumber: number,
  { notes, rawSearchDocument, noFind }: CheckInOrderProps
) => {
  const location = await getLocation();
  const form = new FormData();
  form.append("notes", notes);
  form.append("noFind", String(noFind));
  form.append("latitude", String(location.coords.latitude));
  form.append("longitude", String(location.coords.longitude));
  form.append("timeStamp", new Date().toISOString());

  if (!noFind && rawSearchDocument) {
    form.append("rawSearchDocument", rawSearchDocument); // attach file
  }

  const { data } = await request({
    method: "PUT",
    data: form,
    url: `${config.api.url}/orders/${ordNumber}/close`,
  });

  return data;
};

export const cancelOrder = async (ordNumber: number): Promise<Order> => {
  const { data } = await request({
    method: "PUT",
    url: `${config.api.url}/orders/${ordNumber}/cancel`,
  });

  return data;
};

export const openOrder = async (ordNumber: number): Promise<Order> => {
  const location = await getLocation();
  const { data } = await request({
    method: "PUT",
    data: {
      location: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        timeStamp: new Date().toISOString(),
      },
    },
    url: `${config.api.url}/orders/${ordNumber}/open`,
  });

  return data;
};

export const downloadRunsheet = async (orderNumber: number) => {
  const response = await request({
    url: `${config.api.url}/orders/${orderNumber}/runsheet`,
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `Runsheet-${orderNumber}.pdf`);
  document.body.appendChild(link);
  link.click();
};

export const readRunsheet = async (orderNumber: number) => {
  const response = await request({
    url: `${config.api.url}/orders/${orderNumber}/runsheet`,
    responseType: "blob",
  });

  return response.data;
};

export const pdfexamainer = async (pdfFromResponse: string) => {
  const response = await request({
    url: `${config.api.url}/orders/pdfexamainer`,
    method: "POST",
    data: {
      pdfURLFromResponse: `${pdfFromResponse}`,
    },
    responseType: "blob",
  });

  return response.data;
};

export const pdfexamainerDownload = async (
  pdfFromResponse: string,
  orderNumber: number
) => {
  const response = await request({
    url: `${config.api.url}/orders/pdfexamainer`,
    method: "POST",
    data: {
      pdfURLFromResponse: `${pdfFromResponse}`,
    },
    responseType: "blob",
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `Rawsearch-${orderNumber}.pdf`);
  document.body.appendChild(link);
  link.click();
};

export const downloadRawsearch = async (orderNumber: number) => {
  const response = await request({
    url: `${config.api.url}/orders/${orderNumber}/rawsearchdocument`,
    responseType: "blob",
  });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `Rawsearch-${orderNumber}.pdf`);
  document.body.appendChild(link);
  link.click();
};
